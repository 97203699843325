import React, { useState, useEffect, useCallback, useRef, useMemo, Fragment } from 'react';
import {
   ButtonToolbar,
   Spinner,
   Button,
   Modal,
   Row,
   Col,
   Form,
   Accordion,
} from 'react-bootstrap';
import Select from "react-select";
import { getOEChecklistQuestionsByEntity, getQuestionSets, createQuestionSet, editQuestionSet } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import ActionBtnRendererQuestionSets from 'components/actionBtnRenderer/ActionBtnRendererQuestionSets';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { IcoPlus } from '@icons';
import OeApiResponseRenderer from 'components/OeApiResponseRenderer';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const initialState = {
   name: '',
   description: '',
   checklistQuestions: [],
   legacyType: '',
};

const ChecklistsList = () => {
   const [allQuestions, setAllQuestions] = useState([]);
   const [questionsSets, setQuestionsSets] = useState(null);
   const [questionsSetsMeta, setQuestionsSetsMeta] = useState({});
   const [newQuestionsSet, setNewQuestionsSet] = useState(initialState);
   const optionsLegacyType = useMemo(() => [{ value: 'notices', label: translate('common.notice', 'Bando') }, { value: 'tenders', label: translate('common.tender', 'Gara') }], []);
   const [selectedLegacyType, setSelectedLegacyType] = useState([]);
   const [columnDefs, setColumnDefs] = useState([]);
   const [showModalNew, setShowModalNew] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [refresh, setRefresh] = useState(false);
   const [isFirstResearch, setIsFirstResearch] = useState(true);
   const [sections, setSections] = useState([]);
   const [entities, setEntities] = useState([]);
   const gridRef = useRef();
   const localeText = AG_GRID_LOCALE_IT;
   //console.log('newQuestionsSet', newQuestionsSet);

   const translateEntityType = (entityType) => {
      const textMap = [
         { entityType: 'contracts', translate: 'Contratti'},
         { entityType: 'invoices', translate: 'Fatture'},
         { entityType: 'invoicepayments', translate: 'Pagamenti'},
         { entityType: 'timesheets', translate: 'Timesheet'},
         { entityType: 'tenders', translate: 'Gare'},
         { entityType: 'frameworkContracts', translate: 'Contratti aperti'},
      ];

      let translatedString = textMap.filter(el => el.entityType === entityType).pop();

      return translatedString;
   };

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   const handleCloseModal = (setShowModal) => {
      setSbShow(<></>);
      setNewQuestionsSet(initialState);
      setSelectedLegacyType([]);
      setSections([]);
      setEntities([]);
      setRefresh(true);
      setShowModal(false);
   };

   const handleShowModal = (setShowModal) => setShowModal(true);

   const fetchData = useCallback(async () => {
      getQuestionSets('questionsSets').then((retrievedQuestionsSets) => {
         if (retrievedQuestionsSets.success) {
            setQuestionsSets(retrievedQuestionsSets.data);
            const { data, success, ...restResp } = retrievedQuestionsSets;
            setQuestionsSetsMeta(restResp);
         }
      });
   }, []);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   useEffect(() => {
      if (selectedLegacyType.value) {
         getOEChecklistQuestionsByEntity(selectedLegacyType.value).then((retrievedQuestions) => {
            if (retrievedQuestions.success) {
               let tempQuestions = [...retrievedQuestions.data];
               setAllQuestions(tempQuestions);

               const tempEntities = tempQuestions.filter((value, index, self) => index === self.findIndex((t) => (t.entityType === value.entityType))).map(el => el.entityType)
               setEntities(tempEntities);

               const tempSections = tempQuestions.filter((value, index, self) => index === self.findIndex((t) => (t.sectionName === value.sectionName)))
               setSections(tempSections);
            }
         });
      }
   }, [selectedLegacyType]);

   useEffect(() => {
      if (refresh) {
         fetchData();

         setRefresh(false);
      }
   }, [fetchData, refresh]);

   const onGridReady = useCallback(() => {
      fetchData();
   }, [fetchData]);

   const defaultColDef = {
      resizable: false,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '0 1rem' },
   };

   useEffect(() => {
      setColumnDefs([
         {
            headerName: translate('questionsets.name', 'Nome set'),
            field: 'name',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('questionsets.description', 'Descrizione'),
            field: 'description',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('questionsets.legacyType', 'Entità'),
            field: 'legacyType',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               let legacyType = '';

               switch (params.data.legacyType) {
                  case "notices":
                     legacyType = translate('common.notice', 'Bando');
                     break;
                  case "tenders":
                     legacyType = translate('common.tender', 'Gara');
                     break;
                  default:
                     break;
               }
               return legacyType;
            },
         },
         {
            headerName: '',
            minWidth: 90,
            maxWidth: 90,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: ActionBtnRendererQuestionSets,
            cellRendererParams: {
               optionsLegacyType: optionsLegacyType,
               translateEntityType: translateEntityType,
               setRefresh: setRefresh,
            },
         },
      ]);
   }, [sections, allQuestions, optionsLegacyType]);

   const onChange = (e, fieldName, codeOrSectionName, question, setSelect) => {
      //console.log('e', e);

      let tempNewQuestionsSet = { ...newQuestionsSet };
      let value;

      switch (fieldName) {
         case 'name':
         case 'description':
            value = e.target.value;

            tempNewQuestionsSet[fieldName] = value;
            break;
         case 'checklistQuestions':
            value = e.target.checked;

            if (value) {
               tempNewQuestionsSet[fieldName].push(question);
            } else {
               tempNewQuestionsSet[fieldName] = tempNewQuestionsSet[fieldName].filter(el => el.code !== codeOrSectionName);
            }
            break;
         case 'section':
            value = e.target.checked;

            if (value) {
               tempNewQuestionsSet.checklistQuestions = tempNewQuestionsSet.checklistQuestions.filter(el => el.sectionName !== codeOrSectionName);

               allQuestions.forEach(quest => {
                  if (quest.sectionName === codeOrSectionName) {
                     tempNewQuestionsSet.checklistQuestions.push(quest);
                  }
               });
            } else {
               tempNewQuestionsSet.checklistQuestions = tempNewQuestionsSet.checklistQuestions.filter(el => el.sectionName !== codeOrSectionName);
            }
            break;
         case 'legacyType':
            value = e.value ?? '';

            tempNewQuestionsSet[fieldName] = value;
            tempNewQuestionsSet.checklistQuestions = [];

            setSelect(e);
            break;
         default:
            break;
      }

      setNewQuestionsSet(tempNewQuestionsSet);
   };

   const handleCreate = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      if (!newQuestionsSet.name || !newQuestionsSet.legacyType) {
         handleStatusBar({ success: false, message: translate('common.error.requiredFields', 'Compila i campi obbligatori') });
         return;
      }

      let isOnePerEntityNotices = { contracts: false, invoices: false, invoicepayments: false, timesheets: false};
      let isOnePerEntityTenders = { tenders: false, frameworkContracts: false};
      let isOnePerEntity;

      if (newQuestionsSet.legacyType === 'tenders') isOnePerEntity = isOnePerEntityTenders;
      if (newQuestionsSet.legacyType === 'notices') isOnePerEntity = isOnePerEntityNotices;

      newQuestionsSet.checklistQuestions.forEach(el => {
         isOnePerEntity[el.entityType] = true;
      });

      if (newQuestionsSet.checklistQuestions.length > 0 && !Object.values(isOnePerEntity).every(el => el === true)) {
         handleStatusBar({ 
            success: false, 
            message: translate('common.error.requiredEntities', 'Un set di domande deve avere almeno una domanda selezionata per ogni entità'),
         });
         return;
      }

      const { createdAt, createdBy, updatedAt, updatedBy, checklistQuestions, ...restQuestionsSet } = newQuestionsSet;
      const result = newQuestionsSet.id ? await editQuestionSet(newQuestionsSet.id, restQuestionsSet) : await createQuestionSet(restQuestionsSet);
      //console.log('result', result);

      if (result.success) {
         let checklistQuestionsResult;

         if (!result.data.used) {
            const checklistQuestionsCodes = checklistQuestions.map(quest => quest.code);
            checklistQuestionsResult = await editQuestionSet(result.data.id, { questionCodes: checklistQuestionsCodes }, true);

            if (checklistQuestionsResult.success) {
               document.getElementById("required-field").scrollIntoView({ behavior: 'smooth' });

               handleStatusBar({
                  success: true,
                  message: newQuestionsSet.id ?
                     translate('common.message.edited', 'Elemento modificato con successo') :
                     translate('common.message.created', 'Elemento creato con successo'),
               });

               setNewQuestionsSet(checklistQuestionsResult.data);
            } else {
               handleStatusBar(checklistQuestionsResult);
            }
         } else {
            document.getElementById("required-field").scrollIntoView({ behavior: 'smooth' });

            handleStatusBar({
               success: true,
               message: newQuestionsSet.id ?
                  translate('common.message.edited', 'Elemento modificato con successo') :
                  translate('common.message.created', 'Elemento creato con successo'),
            });
         }
      } else {
         handleShowModal(result);
      }
   };

   return (
      <>
         <div className='simple-box scenaritab'>
            <div className='row d-flex align-items-center mb-3'>
               <div className='col-4'>
                  {/* <h5 className='title-simple-box'>Lista Set</h5> */}
               </div>
               <div className='col-8 text-right px-0 py-1'>
                  <ButtonToolbar className='justify-content-end'>
                     <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
                        <IcoPlus className='light-color ico-small plus new-button' />
                        <span className='d-md-inline'><OETrans code='common.questionsets.addset' fallback={'Nuovo Set'} /></span>
                     </Button>
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={questionsSetsMeta}
                        setData={setQuestionsSets}
                        dataLength={questionsSets?.length}
                        isFirstResearch={isFirstResearch}
                        setIsFirstResearch={setIsFirstResearch}
                        refTable='questionsSets'
                     />
                  </ButtonToolbar>
               </div>
            </div>
            <div className='row'>
               {Array.isArray(questionsSets) ? (
                  <div className='col-12' id='grid-wrapper'>
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={questionsSets}
                           defaultColDef={defaultColDef}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={onGridReady}
                           //onFirstDataRendered={onFirstDataRendered}
                           //onModelUpdated={onModelUpdated}
                           headerHeight={50}
                           rowHeight={50}
                           localeText={localeText}
                           ariaDescription="ag-grid-table"
                           suppressCellFocus={true}
                        />
                        {Object.keys(questionsSetsMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={questionsSetsMeta}
                              setData={setQuestionsSets}
                              setDataMeta={setQuestionsSetsMeta}
                              refTable='questionsSets'
                              setIsFirstResearch={setIsFirstResearch}
                           />
                        )}
                     </div>
                  </div>
               ) : (
                  <div className='text-center my-4'>
                     <Spinner animation='border' role='status'>
                        <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                     </Spinner>
                  </div>
               )}
            </div>
         </div>

         <Modal show={showModalNew} className="modal-with-nav" size='xl' onHide={() => handleCloseModal(setShowModalNew)} centered scrollable>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.questionsets.createset' fallback={'Crea Set Domande'} />
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form onSubmit={handleCreate} id='form-new-setquestion'>
                  <Row className='mb-3' style={{ marginTop: '2rem' }}>
                     <Col className="">
                        <Form.Group as={Col} className='pe-2'>
                           <Form.Label className='mb-3'>
                              <OETrans code='questionsets.name' fallback={'Nome Set'} />&nbsp;<span className='required-field'>*</span>
                           </Form.Label>
                           <Form.Control
                              type='text'
                              name='name'
                              value={newQuestionsSet.name}
                              onChange={(e) => onChange(e, 'name')}
                           />
                        </Form.Group>
                     </Col>

                     <Col className="">
                        <Form.Group as={Col} className='pe-2'>
                           <Form.Label className='mb-3'>
                              <OETrans code='questionsets.legacyType' fallback={'Entità'} />&nbsp;<span className='required-field'>*</span>
                           </Form.Label>
                           <Select
                              components={{
                                 IndicatorSeparator: () => null,
                              }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name="legacyType"
                              placeholder={translate('common.placeholder.select', 'Seleziona...')}
                              value={selectedLegacyType}
                              options={optionsLegacyType}
                              onChange={(e) =>
                                 onChange(e, "legacyType", undefined, undefined, setSelectedLegacyType)
                              }
                           />
                        </Form.Group>
                     </Col>
                  </Row>

                  <Row className='mb-3' style={{ marginTop: '2rem' }}>
                     <Col className="">
                        <Form.Group as={Col} className='pe-2'>
                           <Form.Label className='mb-3'>
                              <OETrans code='questionsets.description' fallback={'Descrizione'} />
                           </Form.Label>
                           <Form.Control
                              as='textarea'
                              rows={2}
                              name='description'
                              value={newQuestionsSet.description ?? ''}
                              onChange={(e) => onChange(e, 'description')}
                           />
                        </Form.Group>
                     </Col>
                  </Row>

                  <Row className='mb-3 pb-3' style={{ marginTop: '2rem' }}>
                     <Form.Label>
                        <OETrans code='common.questionsets.questions' fallback={'Domande'} />&nbsp;<span className='required-field'>*</span>
                     </Form.Label>

                     {selectedLegacyType.length === 0 && <OETrans code='common.questionsets.selectEntity' fallback={"Seleziona un'entità"}/>}

                     {entities.map(entity => {
                        let sectionsByEntity = sections.filter(sect => sect.entityType === entity);

                        return <Fragment key={entity}>
                           <span className='my-2' style={{fontWeight: '600'}}>{translateEntityType(entity).translate}</span>

                           {sectionsByEntity.map((section, index) => {
                              return <Fragment key={section.sectionName}>
                              <Accordion defaultActiveKey="0">
                                 <Accordion.Item eventKey={index}>
                                    <Accordion.Header>
                                       {section.sectionName}&nbsp;
                                       ({allQuestions.filter(quest => {
                                          return newQuestionsSet.checklistQuestions?.some(selectedQuest => {
                                             return selectedQuest.code === quest.code && quest.sectionName === section.sectionName;
                                          });
                                       }).length}/{allQuestions.filter(quest => quest.sectionName === section.sectionName).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                       <Row>
                                          <Col className="col-12 my-2">
                                             <Form.Group>
                                                <Form.Check
                                                   inline
                                                   type='checkbox'
                                                   id={`${section.sectionName}`}
                                                   name={`${section.sectionName}`}
                                                   label={<span style={{ borderBottom: '1px solid #b3b3b3' }}><OETrans code='common.questionsets.selectall' fallback={'Seleziona tutto'} /></span>}
                                                   checked={allQuestions.filter(quest => {
                                                      return newQuestionsSet.checklistQuestions?.some(selectedQuest => {
                                                         return selectedQuest.code === quest.code && quest.sectionName === section.sectionName;
                                                      });
                                                   }).length === allQuestions.filter(quest => quest.sectionName === section.sectionName).length}
                                                   onChange={(e) => onChange(e, 'section', section.sectionName)}
                                                   disabled={newQuestionsSet?.used}
                                                />
                                             </Form.Group>
                                          </Col>
   
                                          {allQuestions.filter(quest => quest.sectionName === section.sectionName)
                                             .sort((valueA, valueB) => valueA.number - valueB.number).map(quest =>
                                                <Col key={quest.code} className="col-6 my-2">
                                                   <Form.Group>
                                                      <Form.Check
                                                         inline
                                                         type='checkbox'
                                                         id={`${quest.code}`}
                                                         name={`${section.sectionName}`}
                                                         label={quest.questions}
                                                         checked={newQuestionsSet.checklistQuestions?.some(el => el.code === quest.code)}
                                                         onChange={(e) => onChange(e, 'checklistQuestions', quest.code, quest)}
                                                         disabled={newQuestionsSet?.used}
                                                      />
                                                   </Form.Group>
                                                </Col>
                                             )}
                                       </Row>
                                    </Accordion.Body>
                                 </Accordion.Item>
                              </Accordion>
                              </Fragment>
                           })}
                        </Fragment>
                     })}
                  </Row>
               </Form>

               {sbShow}

               <Row id='required-field' className='mt-5 mb-2 w-100'>
                  <span className='ms-2'>
                     <span className='required-field'>*</span>
                     &nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'} />
                  </span>
               </Row>
            </Modal.Body>

            <Modal.Footer>
               <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
                  <OETrans code='common.cancel' fallback={'Annulla'} />
               </Button>
               <Button variant='primary' onClick={(e) => handleCreate(e)}>
                  <OETrans code='common.create' fallback={'Crea'} />
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default ChecklistsList;