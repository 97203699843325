import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Row, Tab, Tabs, OverlayTrigger, Tooltip, Col, Form } from 'react-bootstrap';
import { IcoUploadFile, TooltipInfoIcon, /* IcoCheck, IcoCheckFailed */ } from "@icons";
import {
   /* getInherenceChecklists, */
   getAdvertisingChecklists,
   getPlantsAndMaterials,
   downloadProjectDocument,
   downloadProjectEntityDocument,
   projectMassiveUpload,
} from '@lib/api';
import { columnCenteredWithFilter, columnToRight, columnCentered } from '@lib/helpers/agGridHelpers';
import { formatToDecimals } from '@lib/helpers/formatting';
import { months } from '@lib/helpers/calendar';
import { LevelContext } from '@components/contexts/LevelContext';
//import Unauthorized from '@components/Unauthorized';
import OETableAgGrid from '@components/oetable/OETableAgGrid';
import DownloadExcelButton from '@components/form/DownloadExcelButton';
import OeApiResponseRenderer from '@components/OeApiResponseRenderer';
import { OETrans } from 'components/translation/OETrans';

const PhysicalExecution = ({ project }) => {
   const { isOe, userRole } = useContext(LevelContext);
   //const [inherenceRowData, setInherenceRowData] = useState([]);
   //const [inherenceMeta, setInherenceMeta] = useState({});
   const [plantsAndMaterialsRowData, setPlantsAndMaterialsRowData] = useState(null);
   const [plantsAndMaterialsMeta, setPlantsAndMaterialsMeta] = useState({});
   const [advertisingRowData, setAdvertisingRowData] = useState(null);
   const [advertisingMeta, setAdvertisingMeta] = useState([]);
   const [modelFilterString, setModelFilterString] = useState('');
   const [isPending, setIsPending] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   //console.log('advertisingRowData', advertisingRowData);

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };
   const fetchDataAdvertising = useCallback(async () => {
      getAdvertisingChecklists(project.id, 'advertisingchecklists').then((resp) => {
         setAdvertisingRowData(resp.data);
         const { data, success, ...restResp } = resp;
         setAdvertisingMeta(restResp);
      });
   }, [project.id]);

   useEffect(() => {
      if (project.id) {
         fetchDataAdvertising();

         /* getInherenceChecklists(project.id).then((resp) => {
           setInherenceRowData(resp.data);
           const { data, success, ...restResp } = resp;
           setInherenceMeta(restResp);
         }); */

         getPlantsAndMaterials(project.id, 'plantsandmaterials').then((resp) => {
            setPlantsAndMaterialsRowData(resp.data);
            const { data, success, ...restResp } = resp;
            setPlantsAndMaterialsMeta(restResp);
         });
      }
   }, [project.id, fetchDataAdvertising]);

   const StoplightCellRenderer = useCallback((params) => {
      //console.log('params', params)
      const stoplightColor = {
         red: '#C94723',
         yellow: '#D4BE31',
         green: '#689689',
         white: '#ffffff',
         gray: '#C4C4C4', grey: '#C4C4C4',
         green_white: '#689689',
      };

      if (params.value) {
         let circleStyle;
         let message = '';

         if (!params?.value) {
            circleStyle = {
               display: 'inherit',
               width: '1.2em',
               height: '1.2em',
               borderRadius: '2em',
               MozBorderRadius: '2em',
               WebkitBorderRadius: '2em',
               backgroundColor: '#ffffff',
               border: `2px solid ${'#C4C4C4'}`,
            };

            message = 'Da lavorare';
         } else {
            if (params?.value === 'green_white') {
               circleStyle = {
                  display: 'inherit',
                  width: '23px',
                  height: '23px',
                  border: '4px solid #689689',
                  borderRadius: '50%',
                  padding: '0px',
                  background: 'linear-gradient(to right, #689689 50%, transparent 0)',
                  boxSizing: 'border-box',
               };

               message = 'Conforme - sanata';
            } else {
               circleStyle = {
                  display: 'inherit',
                  width: '1.2em',
                  height: '1.2em',
                  borderRadius: '2em',
                  MozBorderRadius: '2em',
                  WebkitBorderRadius: '2em',
                  backgroundColor: stoplightColor[params?.value],
                  border: `2px solid ${params?.value === 'white' ? stoplightColor.grey : stoplightColor[params?.value]}`,
               };

               switch (params?.value) {
                  case 'white':
                     message = 'Da lavorare';
                     break;
                  case 'grey':
                  case 'gray':
                     message = 'In lavorazione';
                     break;
                  case 'red':
                     message = 'Non conforme';
                     break;
                  case 'yellow':
                     message = 'Incompleta';
                     break;
                  case 'green':
                     message = 'Conforme';
                     break;
                  default:
                     break;
               }
            }
         }

         return (
            <>
               <Row
                  className='align-items-center justify-content-center h-100 flex-nowrap'
                  style={{ justifyContent: params.align ?? 'justify-content-center' }}>
                  <Col className='col-auto'>
                     <OverlayTrigger placement='top' overlay={<Tooltip>{message}</Tooltip>}>
                        <i style={{ ...circleStyle }} />
                     </OverlayTrigger>
                  </Col>
               </Row>
            </>
         );
      }
   }, []);

   const TotalResumeChecklistCellRenderer = useCallback((params) => {
      //console.log('params.value', params.value);

      const red = "#C94723";
      const green = "#689689";
      let stoplightColor = "#ffffff";

      if (params.value) {
         stoplightColor = green;
      } else {
         stoplightColor = red;
      }

      let circleStyle = {
         display: 'inherit',
         width: '1.2em',
         height: '1.2em',
         borderRadius: '2em',
         MozBorderRadius: '2em',
         WebkitBorderRadius: '2em',
         backgroundColor: stoplightColor,
         border: `2px solid ${stoplightColor}`,
      };

      /* const reportableTooltip = (props) => (
         <Tooltip {...props}>
            {params.value ? translate('common.yes', 'Sì') : translate('common.no', 'No')}
         </Tooltip>
      ); */

      return (<>
         {/* <OverlayTrigger placement="top" overlay={reportableTooltip}> */}
            <Row className="align-items-center justify-content-center h-100">
               <Col className="col-auto text-center">
                  <i style={{ ...circleStyle }} />
               </Col>
            </Row>
         {/* </OverlayTrigger> */}
         </>
      );
   }, []);

   /* const inherenceColumns = [
     // {
     //   headerName: 'Cup',
     //   field: 'projectCup',
     //   filter: 'agTextColumnFilter',
     //   minWidth: 200,
     // },
     {
       headerName: 'Tipo',
       field: 'type',
       filter: 'agTextColumnFilter',
       minWidth: 150,
     },
 
     {
       headerName: 'Area',
       field: 'area',
       filter: 'agTextColumnFilter',
       minWidth: 150,
     },
     {
       headerName: 'Impianto',
       field: 'plantCode',
       filter: 'agTextColumnFilter',
       minWidth: 200,
     },
     {
       headerName: 'Denominazione Impianto',
       field: 'plantName',
       filter: 'agTextColumnFilter',
       minWidth: 250,
     },
     {
       headerName: 'Fornitore',
       field: 'supplierName',
       filter: 'agTextColumnFilter',
       minWidth: 250,
     },
     {
       headerName: 'Titolo di spesa',
       field: 'invoiceNumber',
       filter: 'agTextColumnFilter',
       minWidth: 200,
     },
     {
       headerName: 'ID Posizione Fattura',
       field: 'invoicePositionId',
       filter: 'agTextColumnFilter',
       minWidth: 220,
     },
     {
       headerName: 'Descrizione',
       field: 'description',
       filter: 'agTextColumnFilter',
       minWidth: 250,
     },
     {
       headerName: 'Q.tà Totale',
       field: 'quantity',
       filter: 'agTextColumnFilter',
       type: 'rightAligned',
       minWidth: 200,
       valueGetter: (params) => {
         return formatToDecimals(params?.data?.quantity);
       },
     },
     {
       headerName: 'Q.tà Installata Sistema',
       field: 'systemInstalledQuantity',
       filter: 'agTextColumnFilter',
       type: 'rightAligned',
       minWidth: 200,
       valueGetter: (params) => {
         return formatToDecimals(params?.data?.systemInstalledQuantity);
       },
     },
     {
       headerName: 'Q.tà Installata Impianto',
       field: 'plantInstalledQuantity',
       filter: 'agTextColumnFilter',
       type: 'rightAligned',
       minWidth: 200,
       valueGetter: (params) => {
         return formatToDecimals(params?.data?.plantInstalledQuantity);
       },
     },
     {
       headerName: 'Esito Check Installazione',
       field: 'installationOutcomeCheck',
       filter: 'agTextColumnFilter',
       minWidth: 200,
     },
     {
       headerName: 'Tipologia Etichetta',
       field: 'labelType',
       filter: 'agTextColumnFilter',
       minWidth: 200,
     },
     {
       headerName: 'Esito Check Etichettatura',
       field: 'labelingOutcomeCheck',
       filter: 'agTextColumnFilter',
       minWidth: 200,
     },
     {
       headerName: 'Anno',
       field: 'year',
       filter: 'agTextColumnFilter',
       ...columnCenteredWithFilter,
       minWidth: 130,
     },
     {
       headerName: 'Mese',
       field: 'month',
       valueFormatter: (params) => {
         const monthIndex = params?.data?.month - 1;
         return months()[monthIndex];
       },
       filter: 'agTextColumnFilter',
       filterValueGetter: (params) => {
         const monthIndex = params?.data?.month - 1;
         return months()[monthIndex];
       },
       ...columnCenteredWithFilter,
       minWidth: 150,
     },
     {
       headerName: 'Rup', // Responsabile Unico di Procedimento
       field: 'rup',
       filter: 'agTextColumnFilter',
       minWidth: 200,
     },
     {
       headerName: 'Rdp',
       field: 'rdp',
       filter: 'agTextColumnFilter',
       minWidth: 200,
     },
     {
       headerName: 'Manager',
       field: 'manager',
       filter: 'agTextColumnFilter',
       minWidth: 250,
     },
   ]; */

   const advertisingColumns = [
      {
         headerName: 'CUP',
         hide: true,
         field: 'project.cup',
         filter: 'agTextColumnFilter',
         minWidth: 200,
      },
      {
         headerName: 'Impanto',
         children: [           
            {
               headerName: 'Codice impianto',
               columnGroupShow: 'open',
               field: 'plant.plantCode',
               filter: 'agTextColumnFilter',
               minWidth: 200,
            },
            {
               headerName: 'Tipologia impianto',
               columnGroupShow: 'open',
               field: 'plant.plantType',
               filter: 'agTextColumnFilter',
               minWidth: 230,
            },
            {
               headerName: 'Denominazione impianto',
               field: 'plant.plantName',
               filter: 'agTextColumnFilter',
               minWidth: 250,
            },
         ]
      },
      {
         headerName: 'Localizzazione',
         columnGroupShow: 'closed',
         minWidth: 550,
         children: [    
            {
               headerName: 'Area',
               field: 'plant.area',
               filter: 'agTextColumnFilter',
               minWidth: 170,
            },       
            {
               headerName: 'Latitudine',
               columnGroupShow: 'open',
               field: 'plant.latitude',
               filter: 'agTextColumnFilter',
               minWidth: 170,
            },
            {
               headerName: 'Longitudine',
               columnGroupShow: 'open',
               field: 'plant.longitude',
               filter: 'agTextColumnFilter',
               minWidth: 170,
            },  
         ]
      },
      {
         headerName: 'Referenti',
         children: [           
            {
               headerName: 'RUP', //Responsabile Unico di Procedimento
               field: 'rup',
               filter: 'agTextColumnFilter',
               minWidth: 200,
            },
            {
               headerName: 'RDP',
               columnGroupShow: 'open',
               field: 'rdp',
               filter: 'agTextColumnFilter',
               minWidth: 200,
            },
            {
               headerName: 'Manager',
               columnGroupShow: 'open',
               field: 'manager',
               filter: 'agTextColumnFilter',
               minWidth: 250,
            },
         ]
      },
      {
         headerName: 'Rilevazione',
         children: [           
            {
               headerName: 'Anno',
               field: 'year',
               filter: 'agTextColumnFilter',
               maxWidth: 150,
               minWidth: 100,
               ...columnCenteredWithFilter,
            },
            {
               headerName: 'Mese',
               field: 'month',
               valueFormatter: (params) => {
                  const monthIndex = params?.data?.month - 1;
                  return months()[monthIndex];
               },
               filter: 'agTextColumnFilter',
               filterValueGetter: (params) => {
                  const monthIndex = params?.data?.month - 1;
                  return months()[monthIndex];
               },
               maxWidth: 230,
               minWidth: 150,
               ...columnCenteredWithFilter,
            },
         ]
      },
      {
         headerName: 'Controlli',
         children: [   
            {
               headerName: 'Check',

               field: 'check',
               cellRenderer: TotalResumeChecklistCellRenderer,
               minWidth: 100,
               maxWidth: 130,
               ...columnCenteredWithFilter,
            },        
            {
               headerName: 'Nuovo impianto',
               columnGroupShow: 'open',
               field: 'isPlantNew',
               valueGetter: (params) => {
                  const value = params.data.isPlantNew ? 'SÌ' : 'NO';
                  return value;
               },
               filter: 'agTextColumnFilter',
               minWidth: 190,
               ...columnCentered(),
            },
            {
               headerName: 'Impianto terminato',
               columnGroupShow: 'open',
               field: 'isPlantDone',
               valueGetter: (params) => {
                  const value = params.data.isPlantNew ? 'SÌ' : 'NO';
                  return value;
               },
               filter: 'agTextColumnFilter',
               minWidth: 210,
               ...columnCentered(),
            },
            {
               headerName: 'Cartellonistica cantiere',
               columnGroupShow: 'open',
               field: 'constructionSiteSignage',
               valueGetter: (params) => {
                  const value = params.data.isPlantNew ? 'SÌ' : 'NO';
                  return value;
               },
               filter: 'agTextColumnFilter',
               minWidth: 250,
               ...columnCentered(),
            },
            {
               headerName: 'Targa commemorativa',
               columnGroupShow: 'open',
               field: 'plate',
               valueGetter: (params) => {
                  const value = params.data.isPlantNew ? 'SÌ' : 'NO';
                  return value;
               },
               filter: 'agTextColumnFilter',
               minWidth: 230,
               ...columnCentered(),
            },
            {
               headerName: 'Registro beni inventariali',              
               columnGroupShow: 'open',
               field: 'goodsRegistry',
               filter: 'agTextColumnFilter',
               minWidth: 250,
            },
         ]
      },
      /* {
        headerName: 'Risposta',
        field: 'answer',
        filter: 'agTextColumnFilter',
        minWidth: 200,
      }, */
   ];

   const plantsAndMaterialsColumns = [
      {
         headerName: 'Impianto',
         columnGroupShow: 'closed',
         children: [
            {
               headerName: 'Denominazione',
               columnGroupShow: 'closed',
               field: 'plantName',
               filter: 'agTextColumnFilter',
               minWidth: 200,
            },
            {
               headerName: 'Impianto di installazione',
               columnGroupShow: 'open',
               children: [
                  {
                     headerName: 'Denominazione',
                     field: 'plantName',
                     filter: 'agNumberColumnFilter',
                     minWidth: 250,
                  },
                  {
                     headerName: 'Codice',
                     columnGroupShow: 'open',
                     field: 'plantCode',
                     filter: 'agTextColumnFilter',
                     minWidth: 180,
                  },
                  {
                     headerName: 'Tipologia',
                     columnGroupShow: 'open',
                     field: 'plantType',
                     filter: 'agTextColumnFilter',
                     minWidth: 180,
                  },
               ],
            },
            {
               headerName: 'Impianto di riferimento',
               columnGroupShow: 'open',

               children: [
                  {
                     headerName: 'Denominazione',
                     field: 'parentPlantName',
                     filter: 'agTextColumnFilter',
                     minWidth: 320,
                  },
                  {
                     headerName: 'Codice',
                     columnGroupShow: 'open',
                     field: 'parentPlantCode',
                     filter: 'agTextColumnFilter',
                     minWidth: 250,
                  },
                  {
                     headerName: 'Tipologia',
                     columnGroupShow: 'open',
                     field: 'parentPlantType',
                     filter: 'agTextColumnFilter',
                     minWidth: 180,
                  },
               ]
            },
         ],
      },
      {
         headerName: 'Materiale',
         children: [
            {
               headerName: 'Bene',
               field: 'descriptionEnel',
               filter: 'agTextColumnFilter',
               minWidth: 250,
            },
            {
               headerName: 'Matricola',
               columnGroupShow: 'open',
               field: 'serialNumberEnel',
               filter: 'agTextColumnFilter',
               minWidth: 210,
            },
            {
               headerName: 'Codice',
               columnGroupShow: 'open',
               field: 'materialCode',
               filter: 'agTextColumnFilter',
               minWidth: 230,
            },
         ]
      },
      {
         headerName: 'Controllo inerenza',
         children: [
            {
               headerName: 'Q. consuntivata',
               field: 'finalQuantity',
               filter: 'agNumberColumnFilter',
               filterParams: {
                  filterOptions: ['equals'],
               },
               valueFormatter: (params) => {
                  return formatToDecimals(params?.data?.finalQuantity, 0, 0);
               },
               minWidth: 150,
               ...columnToRight(),
            },
            {
               headerName: 'Esito controllo inerenza', //Check inerenza
               field: 'inherenceColor',
               cellRenderer: StoplightCellRenderer,
               cellRendererParams: {
                  queryParam: 'inherenceColor',
               },
               minWidth: 250,
               ...columnCentered(),
            },
            {
               headerName: 'Importo consuntivato €',
               columnGroupShow: 'open',
               field: 'finalAmount',
               minWidth: 260,
               valueFormatter: (params) => {
                  return formatToDecimals(params?.data?.finalAmount);
               },
               filter: 'agTextColumnFilter',
               filterParams: {
                  filterOptions: ['equals'],
                  textMatcher: ({ filterOption, value, filterText }) => {
                     //console.log('value', value);
                     //console.log('filterText', filterText);

                     if (filterText == null) return false

                     switch (filterOption) {
                        case 'equals':
                           return value === filterText || formatToDecimals(Number(value)) === filterText;
                        default:
                           return false;
                     }
                  }
               },
               comparator: (valueA, valueB, nodeA, nodeB) => {
                  if (nodeA.data.finalAmount === nodeB.data.finalAmount) return 0;
                  return (nodeA.data.finalAmount > nodeB.data.finalAmount) ? 1 : -1;
               },
               ...columnToRight(),
            },
         ]
      },
      {
         headerName: 'Fornitore',
         children: [
            {
               headerName: 'Fornitore',
               field: 'supplierName',
               filter: 'agTextColumnFilter',
               minWidth: 250,
            },
            {
               headerName: 'Codice',
               columnGroupShow: 'open',
               field: 'supplierCode',
               filter: 'agTextColumnFilter',
               minWidth: 200,
            },
            {
               headerName: 'P.IVA',
               columnGroupShow: 'open',
               field: 'supplierVat',
               filter: 'agTextColumnFilter',
               minWidth: 250,
            },
         ]
      },
      {
         headerName: 'Giustificativi',
         children: [
            {
               headerName: 'Titolo di spesa',
               field: 'erpCode',
               filter: 'agTextColumnFilter',
               minWidth: 200,
            },
            {
               headerName: 'Contratto/attingimento', //ODA
               columnGroupShow: 'open',
               field: 'liOda',
               filter: 'agTextColumnFilter',
               minWidth: 250,
            },
            {
               headerName: 'Contratto aperto',
               columnGroupShow: 'open',
               field: 'frameworkContractNumber',
               filter: 'agTextColumnFilter',
               minWidth: 230,
            },
            {
               headerName: 'Stream',
               columnGroupShow: 'open',
               field: 'streamName',
               filter: 'agTextColumnFilter',
               filterParams: {
                  textMatcher: ({ filterOption, value, filterText }) => {
                     if (filterText == null) return false

                     return value.trim().indexOf(filterText.slice(filterText.indexOf('-') + 1).trim()) >= 0;
                  }
               },
               valueFormatter: (params) => {
                  return `${params?.data?.streamId} - ${params?.data?.streamName}`;
               },
               filterValueFormatter: (params) => {
                  return `${params?.data?.streamId} - ${params?.data?.streamName}`;
               },
               comparator: (valueA, valueB, nodeA, nodeB) => {
                  if (nodeA.data.streamId === nodeB.data.streamId) return 0;
                  return (nodeA.data.streamId > nodeB.data.streamId) ? 1 : -1;
               },
               minWidth: 250,
            },
         ]
      },
   ];

   const handleUpload = async (event) => {
      event?.preventDefault();

      setSbShow(<></>);
      const file = event.target.files[0];

      setIsPending(true);

      // UPLOAD
      const result = await projectMassiveUpload('advertisingchecklists', project.id, file);

      if (result.success) {
         // File upload successful

         handleStatusBar({
            success: true,
            message: 'Documento caricato con successo',
         });

         fetchDataAdvertising();

         setIsPending(false);
      } else {
         handleStatusBar(result);

         setIsPending(false);
      }

      event.target.value = '';
   };

   const renderTooltip = (props) => {
      let type = '';

      if (props.popper.state) {
         type = props.popper.state.options.testObj;
      }

      return (
         <Tooltip id='button-tooltip' {...props}>
            <OETrans code='common.filesallowed' fallback={'Ammessi solo file'}/>:
            <ul style={{ textAlign: 'left', paddingLeft: '20px', margin: 'auto' }}>
               {type === 'excel' ? (
                  <>
                     <li><OETrans code='common.extensionxls' fallback={'con estensione .xls, .xlsx'}/></li>
                  </>
               ) : (
                  <>
                     <li><OETrans code='common.extensionzip' fallback={'con estensione .zip'}/></li>
                     <li><OETrans code='common.noexceed' fallback={'che non superino i 100MB'}/></li>
                  </>
               )}
            </ul>
         </Tooltip>
      );
   };

   // if (!isOe) return <Unauthorized />;

   return (
      <>
         <div className='candidacy simple-box scenaritab'>
            <Row>
               <Tabs variant='pills' defaultActiveKey='installations-report' id='physicalExecution'>
                  {/* <Tab eventKey='inherence' title='Inerenza'>
                     <OETableAgGrid
                        column={inherenceColumns}
                        data={inherenceRowData}
                        dataMeta={inherenceMeta}
                        sortable={true}
                        refTable='inherence'
                     />
                     </Tab>
                  */}
                  <Tab eventKey='installations-report' title='Inerenza'>
                     <OETableAgGrid
                        column={plantsAndMaterialsColumns}
                        data={plantsAndMaterialsRowData}
                        dataMeta={plantsAndMaterialsMeta}
                        sortable={true}
                        otherButtons={
                           <DownloadExcelButton
                              apiDownload={downloadProjectDocument}
                              apiProps={[project.id, 'plantsandmaterials', modelFilterString]}
                           />
                        }
                        setModelFilterString={setModelFilterString}
                        refTable='plantsandmaterials'
                     />
                  </Tab>
                  <Tab eventKey='advertising' title='Pubblicità'>
                     {sbShow}

                     <OETableAgGrid
                        column={advertisingColumns}
                        data={advertisingRowData}
                        dataMeta={advertisingMeta}
                        sortable={true}
                        otherButtons={
                           <DownloadExcelButton
                              apiDownload={downloadProjectEntityDocument}
                              apiProps={[project.id, 'advertisingchecklists']}
                           />
                        }
                        refTable='advertisingchecklists'
                     >
                        {isOe || userRole === 5 ?
                           <Form.Group controlId={`advertising${project.id}_1`}>
                              <Form.Label style={{ cursor: 'pointer', marginBottom: 0 }}>
                                 <IcoUploadFile
                                    className={`ico-small download-upload me-1 align-bottom ${isPending ? 'disabled' : 'second-color'}`}
                                 />
                                 <span><OETrans code='common.importdata' fallback={'Importa dati'}/></span>
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={renderTooltip}
                                    popperConfig={{ testObj: 'excel' }}>
                                    <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                                 </OverlayTrigger>
                              </Form.Label>
                              <Form.Control
                                 type='file'
                                 disabled={isPending ? true : false}
                                 style={{ display: 'none' }}
                                 onChange={(e) => handleUpload(e)}
                              />
                           </Form.Group>
                           : null
                        }
                     </OETableAgGrid>
                  </Tab>
               </Tabs>
            </Row>
         </div>
      </>
   );
};
export default PhysicalExecution;
