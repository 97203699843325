import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatToDecimals } from '@lib/helpers/formatting';
import { columnToRight } from '@lib/helpers/agGridHelpers';
import OESimpleTable from '@components/oetable/OESimpleTable';

const FinancialTotalsTab = ({ financialAmounts }) => {
   const [financials, setFinancials] = useState([]);
   const [totalsByYears, setTotalsByYears] = useState(null);
   const [totalsByExpenseItems, setTotalsByExpenseItems] = useState(null);
   const [columnDefsTotalsByYears, setColumnDefsTotalsByYears] = useState([]);
   const [columnDefsTotalsByExpenseItems, setColumnDefsTotalsByExpenseItems] = useState([]);

   useEffect(() => {
      if (financialAmounts) {
         setFinancials(financialAmounts.sort((a, b) => a.year - b.year).map(el => ({ ...el, residual: el.amount - el.finalAmount })));
      }
   }, [financialAmounts]);

   useEffect(() => {
      if (financials) {
         const financialAmountsWithResidual = JSON.parse(JSON.stringify(financials.sort((a, b) => a.year - b.year).map(el => ({ ...el, residual: el.amount - el.finalAmount }))));

         const tempTest = JSON.parse(JSON.stringify(financialAmountsWithResidual.sort((a, b) => a.posizioneVoceDiSpesa - b.posizioneVoceDiSpesa || a.posizioneSottovoceDiSpesa - b.posizioneSottovoceDiSpesa)));
         const tempTest1 = JSON.parse(JSON.stringify(financialAmountsWithResidual.sort((a, b) => a.posizioneVoceDiSpesa - b.posizioneVoceDiSpesa || a.posizioneSottovoceDiSpesa - b.posizioneSottovoceDiSpesa)));
         let tempTotalsByYears = [];
         let tempTotalsByExpenseItems = [];

         tempTest.forEach(el => {

            if (tempTotalsByYears.some(temp => temp.year === el.year)) {
               //se esiste già l'anno
               const indexOfArray = tempTotalsByYears.findIndex(temp => temp.year === el.year);

               tempTotalsByYears[indexOfArray].amount = tempTotalsByYears[indexOfArray].amount + el.amount;
               tempTotalsByYears[indexOfArray].finalAmount = tempTotalsByYears[indexOfArray].finalAmount + el.finalAmount;
               tempTotalsByYears[indexOfArray].residual = tempTotalsByYears[indexOfArray].residual + el.residual;
            } else {
               //se NON esiste già l'anno
               tempTotalsByYears.push(el);
            }
         });

         setTotalsByYears(tempTotalsByYears);

         tempTest1.forEach(el => {
            if (tempTotalsByExpenseItems.some(temp => temp.voceDiSpesa === el.voceDiSpesa)) {
               //se esiste già la voce di spesa

               if (tempTotalsByExpenseItems.some(temp => temp.projectExpenseItemId === el.projectExpenseItemId)) {
                  //se esiste già la sottovoce di spesa
                  const indexOfArray = tempTotalsByExpenseItems.findIndex(temp => temp.projectExpenseItemId === el.projectExpenseItemId);
                  tempTotalsByExpenseItems[indexOfArray].amount += el.amount;
                  tempTotalsByExpenseItems[indexOfArray].finalAmount += el.finalAmount;
                  tempTotalsByExpenseItems[indexOfArray].residual += el.residual;
               } else {
                  //se NON esiste già la sottovoce di spesa
                  tempTotalsByExpenseItems.push(el);
               }

            } else {
               //se NON esiste già la voce di spesa
               if (tempTotalsByExpenseItems.some(temp => temp.projectExpenseItemId === el.projectExpenseItemId)) {
                  //se esiste già la sottovoce di spesa
                  const indexOfArray = tempTotalsByExpenseItems.findIndex(temp => temp.projectExpenseItemId === el.projectExpenseItemId);

                  let tempEl = {...el, 
                     amount: tempTotalsByExpenseItems[indexOfArray].amount + el.amount,
                     finalAmount: tempTotalsByExpenseItems[indexOfArray].finalAmount + el.finalAmount,
                     residual: tempTotalsByExpenseItems[indexOfArray].residual + el.residual,
                  };

                  tempTotalsByExpenseItems.push(tempEl);
               } else {
                  //se NON esiste già la sottovoce di spesa
                  tempTotalsByExpenseItems.push(el);
               }
            }
         });

         setTotalsByExpenseItems(tempTotalsByExpenseItems);
      }
   }, [financials]);

   useEffect(() => {
      setColumnDefsTotalsByYears([
         {
            headerName: 'Anno',
            field: 'year',
            filter: 'agTextColumnFilter',
            //minWidth: 120,
         },
         {
            headerName: 'Spesa prevista (€)',
            field: 'amount',
            filter: 'agTextColumnFilter',
            valueFormatter: (params) => {
               return formatToDecimals(params.data.amount);
            },
            filterValueGetter: (params) => {
               return formatToDecimals(params.data.amount);
            },
            valueGetter: (params) => {
               return params.data.amount > 0 ? params.data.amount : 0;
            },
            minWidth: 250,
            maxWidth: 250,
            ...columnToRight(),
         },
         {
            headerName: 'Spesa effettuata (€)',
            field: 'finalAmount',
            filter: 'agTextColumnFilter',
            valueFormatter: (params) => {
               return formatToDecimals(params.data.finalAmount);
            },
            filterValueGetter: (params) => {
               return formatToDecimals(params.data.finalAmount);
            },
            valueGetter: (params) => {
               return params.data.finalAmount > 0 ? params.data.finalAmount : 0;
            },
            minWidth: 250,
            maxWidth: 250,
            ...columnToRight(),
         },
         {
            headerName: 'Spesa residua (€)',
            field: 'residual',
            filter: 'agTextColumnFilter',
            filterValueGetter: (params) => {
               return formatToDecimals(params.data.residual);
            },
            valueGetter: (params) => {
               return params.data.residual !== 0 ? params.data.residual : 0;
            },
            cellRenderer: (params) => {
               return formatToDecimals(params.data.residual);
            },
            minWidth: 250,
            maxWidth: 250,
            ...columnToRight(),
         },
      ]);

      setColumnDefsTotalsByExpenseItems([
         {
            headerName: 'Voce di spesa',
            field: 'voceDiSpesa',
            filter: 'agTextColumnFilter',
            cellRenderer: rowspanCellRenderer,
         },
         {
            headerName: 'Sottovoce di spesa',
            field: 'sottovoceDiSpesa',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: 'Spesa prevista (€)',
            field: 'amount',
            filter: 'agTextColumnFilter',
            valueFormatter: (params) => {
               return formatToDecimals(params.data.amount);
            },
            filterValueGetter: (params) => {
               return formatToDecimals(params.data.amount);
            },
            valueGetter: (params) => {
               return params.data.amount > 0 ? params.data.amount : 0;
            },
            minWidth: 250,
            maxWidth: 250,
            ...columnToRight(),
         },
         {
            headerName: 'Spesa effettuata (€)',
            field: 'finalAmount',
            filter: 'agTextColumnFilter',
            valueFormatter: (params) => {
               return formatToDecimals(params.data.finalAmount);
            },
            filterValueGetter: (params) => {
               return formatToDecimals(params.data.finalAmount);
            },
            valueGetter: (params) => {
               return params.data.finalAmount > 0 ? params.data.finalAmount : 0;
            },
            minWidth: 250,
            maxWidth: 250,
            ...columnToRight(),
         },
         {
            headerName: 'Spesa residua (€)',
            field: 'residual',
            filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
               return formatToDecimals(params.data.residual);
            },
            filterValueGetter: (params) => {
               return formatToDecimals(params.data.residual);
            },
            valueGetter: (params) => {
               return params.data.residual !== 0 ? params.data.residual : 0;
            },
            minWidth: 250,
            maxWidth: 250,
            ...columnToRight(),
         },
      ]);
   }, []);

   const rowspanCellRenderer = (params) => {
      if (params.node.rowIndex > 0 && params.data[params.colDef.field] === params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1).data[params.colDef.field]) {
         return '';
      } else {
         return params.valueFormatted || params.value;
      }
   };

   return (
      <>
         <Row className="mt-2 mb-5">
            <Col>
               <OESimpleTable
                  columnDefs={columnDefsTotalsByYears}
                  rowData={totalsByYears}
                  pageSizeId={'financial-totals-years-table'}
                  sortable={true}
                  pinnedBottomRow={true}
                  bottomColumns={[{ year: "TOTALE" }, 'amount', 'finalAmount', 'residual']}
                  fullWidth={true}
               />
            </Col>
         </Row>

         <Row className="mt-5 mb-4">
            <Col>
               <OESimpleTable
                  columnDefs={columnDefsTotalsByExpenseItems}
                  rowData={totalsByExpenseItems}
                  pageSizeId={'financial-totals-expense-items-table'}
                  sortable={true}
                  pinnedBottomRow={true}
                  bottomColumns={[{ voceDiSpesa: "TOTALE" }, 'amount', 'finalAmount', 'residual']}
               />
            </Col>
         </Row>
      </>
   );
};
export default FinancialTotalsTab;
