import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
  FloatingLabel,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import {
  getNotice,
  editNotice,
  deleteNotice,
  getNoticesContactPerson,
  createNoticeContactPerson,
  editNoticeContactPerson,
  deleteNoticeContactPerson,
  getNoticesDocumentsOfAccountability,
  createNoticesDocumentsOfAccountability,
  editNoticesDocumentsOfAccountability,
  deleteNoticesDocumentsOfAccountability,
  getNoticesTargets,
  createNoticeTarget,
  editNoticeTarget,
  deleteNoticeTarget,
  createNoticeExpenseItems,
  editNoticeExpenseItems,
  deleteNoticeExpenseItems,
  createNoticeSubExpenseItems,
  editNoticeSubExpenseItems,
  deleteNoticeSubExpenseItems,
  getNoticesEngagementRules,
  createNoticeEngagementRules,
  editNoticeEngagementRules,
  deleteNoticeEngagementRules,
  getOePnrrTaxonomyValues,
  editQuestionSetByEntity,
  downloadControlsReport,
} from "@lib/api";
import {
  IcoCanc,
  IcoEdit,
  IcoPlus,
  IcoMinusCircled,
  TooltipInfoIcon,
  IcoDownloadFile,
} from "@icons";
import UploadDocument from "components/form/upload/uploadDocuments";
import OeApiResponseRenderer from "components/OeApiResponseRenderer";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const ActionBtnRendererNotices = ({
  data,
  setRefresh,
  fundTaxonomies,
  optionsFunds,
  optionsInstruments,
  optionsInstitutions,
  projects,
  optionsQuestionSet,
}) => {
  const id = data.id;
  const [notice, setNotice] = useState({});
  const [selectedFund, setSelectedFund] = useState([]);
  const [selectedInstrument, setSelectedInstrument] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState([]);
  const [taxonomyInstances, setTaxonomyInstances] = useState([]);
  const [pnrrTaxonomies, setPnrrTaxonomies] = useState({});
  const [currentPnrrTaxonomies, setCurrentPnrrTaxonomies] = useState({});
  const [contactPersons, setContactPersons] = useState([]);
  const [targets, setTargets] = useState([]);
  const [docsOfAccountability, setDocsOfAccountability] = useState([]);
  const [originDocsOfAccountability, setOriginDocsOfAccountability] = useState(
    []
  );
  const [allExpenseItems, setAllExpenseItems] = useState([]);
  const [engagementRules, setEngagementRules] = useState([]);
  const [originContactPersons, setOriginContactPersons] = useState([]);
  const [originTargets, setOriginTargets] = useState([]);
  const [originAllExpenseItems, setOriginAllExpenseItems] = useState([]);
  const [deletedSubExpenseItems, setDeletedSubExpenseItems] = useState([]);
  const [originEngagementRules, setOriginEngagementRules] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [activeKey, setActiveKey] = useState("registry");
  const [isUsed, setIsUsed] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
  //console.log('notice:', notice);

  useEffect(() => {
    setIsUsed(projects.some((proj) => proj.noticeId === id));

    if (showModalEdit) {
      getNotice(id).then((retrievedNotice) => {
        if (retrievedNotice.data) {
          setNotice(retrievedNotice.data);
          setTaxonomyInstances(retrievedNotice.data.taxonomyinstances);

          let tempAllExpenseItems = JSON.parse(
            JSON.stringify(retrievedNotice.data.expenseItems)
          );

          tempAllExpenseItems.forEach((expItem) => {
            if (expItem.subExpenseItems.length === 0) {
              expItem.subExpenseItems = [
                {
                  name: "",
                  description: "",
                  fakeExpenseItemId: expItem.id || expItem.fakeId,
                },
              ];
            }
          });

          setAllExpenseItems(tempAllExpenseItems);
          setOriginAllExpenseItems(retrievedNotice.data.expenseItems);

          setNotice(retrievedNotice.data);
        }
      });

      getNoticesContactPerson(id).then((retrievedContactPersons) => {
        setContactPersons(retrievedContactPersons.data);
        setOriginContactPersons(retrievedContactPersons.data);
      });

      getNoticesDocumentsOfAccountability(id).then(
        (retrievedDocsOfAccountability) => {
          setDocsOfAccountability(retrievedDocsOfAccountability.data);
          setOriginDocsOfAccountability(retrievedDocsOfAccountability.data);
        }
      );

      getNoticesTargets(id).then((retrievedTargets) => {
        setTargets(retrievedTargets.data);
        setOriginTargets(retrievedTargets.data);
      });

      getNoticesEngagementRules(id).then((retrievedRules) => {
        setEngagementRules(retrievedRules.data);
        setOriginEngagementRules(retrievedRules.data);
      });

      if (data.fundId === 1) {
        getOePnrrTaxonomyValues().then((retrievedOepnrr) => {
          setPnrrTaxonomies(retrievedOepnrr.data);
          setCurrentPnrrTaxonomies(retrievedOepnrr.data);
        });
      }
    }
  }, [data, id, projects, showModalEdit]);

  useEffect(() => {
    setSelectedFund(
      optionsFunds
        .filter((option) => option.value === notice.fundId)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
        }))
    );

    setSelectedInstrument(
      optionsInstruments
        .filter((option) => option.value === notice.instrumentId)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedInstitution(
      optionsInstitutions
        .filter((option) => option.value === notice.institutionId)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedQuestionSet(optionsQuestionSet.filter((option) => option.value === notice.questionsetId));
  }, [notice, optionsFunds, optionsInstitutions, optionsInstruments, optionsQuestionSet]);

  useEffect(() => {
    if (isConfirmed) {
      handleEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(null);
    setActiveKey("registry");
    setRefresh(true);
    setShowModal(false);
  };

  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleShowModalTab = (setShowModal, tab) => {
    setShowModal(true);
    setActiveKey(tab);
  };

  const handleStatusBar = (dataToHandle) => {
    setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
  };

  const refreshTaxonomiesOptions = (elCode, val) => {
    let tempCurrentPnrrTaxonomies = { ...currentPnrrTaxonomies };

    // TODO: refactor this switch
    let code = ""; // eslint-disable-line
    switch (elCode) {
      case "PNRRMISSION":
        code = "missione";

        tempCurrentPnrrTaxonomies["componente"] = pnrrTaxonomies[
          "componente"
        ].filter((tax) => tax.code.indexOf(val) === 0);

        tempCurrentPnrrTaxonomies["misura"] = pnrrTaxonomies["misura"].filter(
          (tax) => tax.code.indexOf(val) === 0
        );

        tempCurrentPnrrTaxonomies["submisura"] = pnrrTaxonomies[
          "submisura"
        ].filter((tax) => tax.code.indexOf(val) === 0);

        tempCurrentPnrrTaxonomies["investimento"] = pnrrTaxonomies[
          "investimento"
        ].filter((tax) => tax.code.indexOf(val) === 0);
        break;
      case "PNRRCOM":
        code = "componente";

        tempCurrentPnrrTaxonomies["misura"] = pnrrTaxonomies["misura"].filter(
          (tax) => tax.code.indexOf(val) === 0
        );

        tempCurrentPnrrTaxonomies["submisura"] = pnrrTaxonomies[
          "submisura"
        ].filter((tax) => tax.code.indexOf(val) === 0);

        tempCurrentPnrrTaxonomies["investimento"] = pnrrTaxonomies[
          "investimento"
        ].filter((tax) => tax.code.indexOf(val) === 0);
        break;
      case "PNRRMIS":
        code = "misura";

        tempCurrentPnrrTaxonomies["submisura"] = pnrrTaxonomies[
          "submisura"
        ].filter((tax) => tax.code.indexOf(val) === 0);

        break;
      case "PNRRINV":
        code = "investimento";
        break;

      case "PNRRSUBMIS":
        code = "submisura";
        break;
      case "PNRRAMM":
        code = "amministrazioneTitolare"; // eslint-disable-line
        break;
      default:
        break;
    }

    setCurrentPnrrTaxonomies(tempCurrentPnrrTaxonomies);
  };

  const onChange = (
    e,
    fieldName,
    setSelect,
    elCode,
    elIndex,
    list,
    parentIndex
  ) => {
    //console.log('e', e, fieldName, elCode);
    //(e, 'targets', undefined, undefined, i)

    let tempNotice = { ...notice };
    let value;

    switch (fieldName) {
      case "name":
      case "publishedAt":
      case "expiresAt":
      case "objectives":
      case "timeframe":
      case "managingSystem":
      case "compounding":
      case "changeManagement":
      case "disbursement":
      case "admissibleCosts":
      case "call":
        value = e.target.value;

        tempNotice[fieldName] = value;
        break;
      case "fundId":
      case "instrumentId":
      case "institutionId":
        value = e.value ?? [];

        tempNotice[fieldName] = value;

        if (fieldName === "institutionId") tempNotice.institutionCode = e.code;

        if (fieldName === "fundId" && tempNotice.fundId !== 1) {
          tempNotice.taxonomyinstances = tempNotice.taxonomyinstances.map(
            (obj) => {
              return { ...obj, value: null };
            }
          );
        }

        if (
          fieldName === "institutionId" &&
          tempNotice.fundId === 1 &&
          tempNotice.taxonomyinstances.length > 0
        ) {
          let indexOfPNRRAMM = tempNotice.taxonomyinstances.findIndex(
            (el) => el.fundTaxonomyCode === "PNRRAMM"
          );
          tempNotice.taxonomyinstances[indexOfPNRRAMM].value =
            tempNotice.institutionCode;
        }

        setSelect(e);

        tempNotice[fieldName] = value;
        break;
      case "taxonomyinstances":
        let index = tempNotice.taxonomyinstances.findIndex(
          (tax) => tax.fundTaxonomyCode === elCode
        );

        tempNotice.taxonomyinstances[index].value = e.target
          ? e.target.value
          : e.value;

        setSelect(tempNotice.taxonomyinstances);

        if (tempNotice.fundId === 1)
          refreshTaxonomiesOptions(
            elCode,
            tempNotice.taxonomyinstances[index].value
          );
        break;
      case "contactPersons":
        //case 'subExpenseItems':
        let tempPerson = [...list];

        let tempPersonElement = {
          ...tempPerson[elIndex],
          [e.target.name]: e.target.value,
          noticeId: id,
          //...fieldName === 'allExpenseItems' && { position: elIndex},
        };

        tempPerson[elIndex] = tempPersonElement;

        if (
          !tempPersonElement.email &&
          !tempPersonElement.firstName &&
          !tempPersonElement.lastName &&
          !tempPersonElement.phone
        )
          tempPerson = tempPerson.filter(
            (el) => el.name && el.firstName && el.lastName && el.phone
          );

        setSelect(tempPerson); //as setList
        break;
      case "targets":
      case "docsOfAccountabilities":
      case "expenseItems":
      case "allExpenseItems":
      case "engagementRules":
        //case 'subExpenseItems':
        let tempList = [...list];

        let tempElement = {
          ...tempList[elIndex],
          [e.target.name]: e.target.value,
          noticeId: id,
          //...fieldName === 'allExpenseItems' && { position: elIndex},
        };

        if (fieldName === "engagementRules" && tempElement.name === "")
          tempElement.content = "";

        tempList[elIndex] = tempElement;

        setSelect(tempList); //as setList
        break;
      case "subExpenseItems":
        let tempAllExpenseItems = [...allExpenseItems];
        let tempListOfSubs = [...list]; //list of subs

        tempListOfSubs[elIndex][e.target.name] = e.target.value;

        tempAllExpenseItems[parentIndex].subExpenseItems = tempListOfSubs;

        setSelect(tempAllExpenseItems); //as setList
        break;
      case "environmentalTagging":
      case "digitalTagging":
      case "genderEquality":
      case "dnsh":
        value = e.target.checked;
        tempNotice[fieldName] = value;

        break;
      case "questionsetId":
         value = e ? e.value : null;
         tempNotice[fieldName] = value;

         setSelect(e);
         break;
      case "docs":
        break;
      default:
        value = e.target.value;
        tempNotice[fieldName] = value;
    }

    setNotice(tempNotice);
  };

  const onBlur = (value, regex, setState) => {
    if ((value && value.match(regex)) || value === "") {
      setState(false);
    } else if (value && !value.match(regex)) {
      setState(true);
    }
  };

  const checkConfirmation = async (boolean) => {
    setIsConfirmed(boolean);

    boolean ? handleEdit() : setShowModalWarning(false);
  };

  const handleEdit = async (e) => {
    setSbShow(null);

    if (
      isUsed &&
      (JSON.stringify(originTargets) !== JSON.stringify(targets) ||
        JSON.stringify(originAllExpenseItems) !==
          JSON.stringify(allExpenseItems.map(({ fakeId, ...rest }) => rest)) ||
        JSON.stringify(originDocsOfAccountability) !==
          JSON.stringify(docsOfAccountability)) &&
      !isConfirmed
    ) {
      setShowModalWarning(true);
      return;
    }

    if (
      !notice.name ||
      !notice.fundId ||
      !notice.instrumentId ||
      !notice.institutionId ||
      !notice.institutionCode ||
      !notice.publishedAt ||
      !notice.expiresAt ||
      !notice.call ||
      !notice.questionsetId
    ) {
      handleStatusBar({
        success: false,
        message: translate('common.error.requiredFields', 'Compila i campi obbligatori'),
      });
      return;
    }

    if (
      new Date(notice.publishedAt).getTime() >
      new Date(notice.expiresAt).getTime()
    ) {
      handleStatusBar({
        success: false,
        message: translate('common.error.previousdatepublished', 'La pubblicazione deve avere una data antecedente alla scadenza'),
      });

      return;
    }

    if (
      contactPersons.length > 0 &&
      contactPersons.every(
        (contactPerson) =>
          contactPerson.email && !contactPerson.email.match(emailRegex)
      )
    ) {
      handleStatusBar({ success: false, message: translate('common.error.email', 'Email non conforme') });

      return;
    }

    const setDOA = new Set();
    const isNotNameDocsOfAccountabilityUnique = docsOfAccountability.some(
      (docOfAccountability) =>
        setDOA.size === (setDOA.add(docOfAccountability.name), setDOA.size)
    );

    if (isNotNameDocsOfAccountabilityUnique) {
      handleStatusBar({
        success: false,
        message: translate('common.error.reportingdocumentsname', 'I documenti di rendicontazione non possono avere nomi uguali'),
      });

      return;
    }

    if (
      docsOfAccountability.length > 0 &&
      !docsOfAccountability.every((el) => el.name && el.description)
    ) {
      handleStatusBar({
        success: false,
        message: translate('common.error.reportingdocumentsnamedescription', 'I documenti di rendicontazione devono avere un nome e una descrizione'),
      });

      return;
    }

    const set = new Set();
    const isNotNameTargetsUnique = targets.some(
      (target) => set.size === (set.add(target.name), set.size)
    );

    if (isNotNameTargetsUnique) {
      handleStatusBar({
        success: false,
        message: translate('common.error.targetsname', 'Gli obiettivi non possono avere nomi uguali'),
      });

      return;
    }

    if (targets.length > 0 && !targets.every((el) => el.name && el.uom)) {
      handleStatusBar({
        success: false,
        message: translate('common.error.targetsnameuom', 'Gli obiettivi devono avere un nome e un’unità di misura'),
      });

      return;
    }
    const set1 = new Set();
    const isNotNameExpenseItemsUnique = allExpenseItems.some(
      (parentExpenseItem) =>
        set1.size === (set1.add(parentExpenseItem.name), set1.size)
    );

    if (isNotNameExpenseItemsUnique) {
      handleStatusBar({
        success: false,
        message: translate('common.error.expenseitemsname', 'Le voci di spesa non possono avere nomi uguali'),
      });

      return;
    }

    if (allExpenseItems.length > 0) {
      let test = false;

      allExpenseItems.forEach((expenseItem) => {
        const set2 = new Set();
        const isNotNameSubExpenseItemsUnique = expenseItem.subExpenseItems.some(
          (subExpenseItem) =>
            set2.size === (set2.add(subExpenseItem.name), set2.size)
        );

        test = test || isNotNameSubExpenseItemsUnique;
      });

      if (test) {
        handleStatusBar({
          success: false,
          message: translate('common.error.subexpenseitemsname', 'Le sottovoci di spesa non possono avere nomi uguali nella stessa voce'),
        });

        return;
      }
    }

    setShowSpinner(true);

    const { createdAt, createdBy, updatedAt, updatedBy, ...restNotice } =
      notice;

    if (notice.taxonomyinstances.length > 0) {
      //Add position to taxonomyinstances
      const taxonomyInstancesWithPosition = restNotice.taxonomyinstances.map(
        (el) => {
          const taxonomy = fundTaxonomies.find(
            (tax) => tax.code === el.fundTaxonomyCode
          );
          return { ...el, position: taxonomy.position };
        }
      );

      setTaxonomyInstances(taxonomyInstancesWithPosition);

      restNotice.taxonomyinstances = taxonomyInstancesWithPosition;

      setNotice(restNotice);
    }

    const result = await editNotice(restNotice);

    setShowSpinner(false);

    if (result.success) {
      let resultOfActions = [true];
      let resultOfMessages = [];

      // let contactPersonsCleaned = contactPersons.filter(
      //   (contactPerson) =>
      //     contactPerson.firstName &&
      //     contactPerson.lastName &&
      //     contactPerson.email &&
      //     contactPerson.phone
      // );

      let docOfAccountabilityCleaned = docsOfAccountability.filter(
        (docOfAccountability) =>
          docOfAccountability.name && docOfAccountability.description
      );

      let targetsCleaned = targets.filter(
        (target) => target.name && target.uom
      );

      let allExpenseItemsCleaned = allExpenseItems
        .filter(
          (expItem) =>
            expItem.name &&
            expItem.subExpenseItems.length >
              0 /* || expItem.subExpenseItems.every(el => el.name) */
        )
        .map((expItemFiltered, i) => ({ ...expItemFiltered, position: i }));

      allExpenseItemsCleaned.forEach((expItem) => {
        let tempSubExpenseItems = expItem.subExpenseItems.map(
          (subExpItem, i) => ({
            ...subExpItem,
            position: i,
          })
        );

        expItem.subExpenseItems = tempSubExpenseItems;
      });
      //console.log('allExpenseItemsCleaned', allExpenseItemsCleaned);

      let engagementRulesCleaned = engagementRules.filter(
        (engagementRule) => engagementRule.name
      );
      //console.log('engagementRulesCleaned', engagementRulesCleaned);

      let [
        contactPersonsResult,
        targetsResult,
        docsOfAccountabilityResult,
        allExpenseItemsResult,
        engagementRulesResult,
      ] = await Promise.all([
        handleItemList(
          "contactPersons",
          originContactPersons,
          // contactPersonsCleaned,
          contactPersons,
          deleteNoticeContactPerson,
          editNoticeContactPerson,
          createNoticeContactPerson
        ),
        handleItemList(
          "targets",
          originTargets,
          targetsCleaned,
          deleteNoticeTarget,
          editNoticeTarget,
          createNoticeTarget
        ),
        handleItemList(
          "docsOfAccountabilities",
          originDocsOfAccountability,
          docOfAccountabilityCleaned,
          deleteNoticesDocumentsOfAccountability,
          editNoticesDocumentsOfAccountability,
          createNoticesDocumentsOfAccountability
        ),
        handleItemList(
          "allExpenseItems",
          originAllExpenseItems,
          allExpenseItemsCleaned,
          deleteNoticeExpenseItems,
          editNoticeExpenseItems,
          createNoticeExpenseItems
        ),
        handleItemList(
          "engagementRules",
          originEngagementRules,
          engagementRulesCleaned,
          deleteNoticeEngagementRules,
          editNoticeEngagementRules,
          createNoticeEngagementRules
        ),
      ]);

      let questionSetsResult;
      let questionSetsResultBool;

      questionSetsResult = await editQuestionSetByEntity('notices', notice.id, notice.questionsetId);
      //console.log('questionSetsResult prima', questionSetsResult);

      questionSetsResultBool = questionSetsResult.success;
      //console.log('questionSetsResult dopo', questionSetsResult);

      if (!questionSetsResultBool) {
         handleStatusBar(questionSetsResult);
         return
      }

      //console.log('engagementRulesResult', engagementRulesResult);
      const resultAll = resultOfActions.concat(
        contactPersonsResult ? contactPersonsResult.resultOfActionsTemp : [],
        targetsResult ? targetsResult.resultOfActionsTemp : [],
        docsOfAccountabilityResult
          ? docsOfAccountabilityResult.resultOfActionsTemp
          : [],
        allExpenseItemsResult ? allExpenseItemsResult.resultOfActionsTemp : [],
        engagementRulesResult ? engagementRulesResult.resultOfActionsTemp : [],
        [questionSetsResultBool]
      );
      const resultOfAllMessages = resultOfMessages.concat(
        contactPersonsResult ? contactPersonsResult.messageResult : [],
        targetsResult ? targetsResult.messageResult : [],
        docsOfAccountabilityResult
          ? docsOfAccountabilityResult.messageResult
          : [],
        allExpenseItemsResult ? allExpenseItemsResult.messageResult : [],
        engagementRulesResult ? engagementRulesResult.messageResult : []
      );
      //console.log('resultAll:', resultAll);
      //console.log('resultOfAllMessages:', resultOfAllMessages);

      if (resultAll.length > 0 && resultAll.every((el) => el === true)) {
        handleStatusBar(resultOfAllMessages);
        // handleStatusBar('success', translate('common.message.edited', 'Elemento modificato con successo'), true);

        setTimeout(() => {
          getNotice(id).then((retrievedNotice) => {
            setNotice(retrievedNotice.data);
            setTaxonomyInstances(retrievedNotice.data.taxonomyinstances);

            if (retrievedNotice.data) {
              let tempAllExpenseItems = [...retrievedNotice.data.expenseItems];

              tempAllExpenseItems.forEach((expItem) => {
                if (expItem.subExpenseItems.length === 0) {
                  expItem.subExpenseItems = [
                    {
                      name: "",
                      description: "",
                      fakeExpenseItemId: expItem.id || expItem.fakeId,
                    },
                  ];
                }
              });

              setAllExpenseItems(tempAllExpenseItems);
              setOriginAllExpenseItems(retrievedNotice.data.expenseItems);
            }
          });

          getNoticesContactPerson(id).then((retrievedContactPersons) => {
            setContactPersons(retrievedContactPersons.data);
            setOriginContactPersons(retrievedContactPersons.data);
          });

          getNoticesDocumentsOfAccountability(id).then(
            (retrievedDocsOfAccountability) => {
              setDocsOfAccountability(retrievedDocsOfAccountability.data);
              setOriginDocsOfAccountability(retrievedDocsOfAccountability.data);
            }
          );

          getNoticesTargets(id).then((retrievedTargets) => {
            setTargets(retrievedTargets.data);
            setOriginTargets(retrievedTargets.data);
          });

          getNoticesEngagementRules(id).then((retrievedRules) => {
            setEngagementRules(retrievedRules.data);
            setOriginEngagementRules(retrievedRules.data);
          });

          if (data.fundId === 1) {
            getOePnrrTaxonomyValues().then((retrievedOepnrr) => {
              setPnrrTaxonomies(retrievedOepnrr.data);
              setCurrentPnrrTaxonomies(retrievedOepnrr.data);
            });
          }
        }, 2000);
      } else {
        handleStatusBar(resultOfAllMessages);
      }
    } else {
      handleStatusBar(result);
    }

    setShowSpinner(false);
    setIsConfirmed(false);
    setDeletedSubExpenseItems([]);
  };

  const handleItemList = async (
    entityType,
    originItemList,
    itemList,
    deleteAPI,
    editAPI,
    createAPI
  ) => {
    let resultOfActionsTemp = [];
    let messageResult;
    //console.log('originItemList:', entityType, originItemList);
    //console.log('itemList:', entityType, itemList);

    if (entityType === "allExpenseItems") {
      const filteredSubItems = deletedSubExpenseItems.filter((object1) => {
        return allExpenseItems.some((object2) => {
          return object1.parentId === object2.id;
        });
      });
      //console.log('filteredSubItems', filteredSubItems);

      for (let filteredSubItem of filteredSubItems) {
        const deleteResult = await deleteNoticeSubExpenseItems(
          id,
          filteredSubItem.parentId,
          filteredSubItem.subId
        );
        if (deleteResult.success === false) messageResult = deleteResult;
        //console.log('deleteResult sub:', deleteResult);
        resultOfActionsTemp.push(deleteResult.success);
      }
    }

    const oldItemsDeleted = originItemList.filter((object1) => {
      return !itemList.some((object2) => {
        return object1.id === object2.id;
      });
    });

    if (oldItemsDeleted.length > 0) {
      oldItemsDeleted.forEach(async (item) => {
        const deleteResult = await deleteAPI(id, item.id);
        if (deleteResult.success === false) messageResult = deleteResult;
        //console.log('deleteResult:', deleteResult);
        resultOfActionsTemp.push(deleteResult.success);
      });
    }

    let validationFields = [];

    switch (entityType) {
      case "targets":
        validationFields = ["name", "uom"];
        break;
      case "docsOfAccountabilities":
        validationFields = ["name", "description"];
        break;
      case "allExpenseItems":
        validationFields = ["name"];
        break;
      case "engagementRules":
        validationFields = ["name"];
        break;
      default:
        validationFields = [];
    }

    let result;
    let subResult;

    for (let item of itemList) {
      if (validationFields.every((field) => item[field])) {
        if (item.id) {
          if (entityType === "allExpenseItems") {
            //item.subExpenseItems.forEach(async subItem => {
            for (let subItem of item.subExpenseItems) {
              if (subItem.name) {
                if (subItem.id) {
                  const {
                    createdAt,
                    createdBy,
                    updatedAt,
                    updatedBy,
                    ...restSubItem
                  } = subItem;
                  subResult = await editNoticeSubExpenseItems(
                    id,
                    item.id,
                    restSubItem
                  );
                  if (subResult.success === false) messageResult = subResult;
                  resultOfActionsTemp.push(subResult.success);
                } else {
                  subResult = await createNoticeSubExpenseItems(
                    id,
                    item.id,
                    subItem
                  );
                  if (subResult.success === false) messageResult = subResult;
                  resultOfActionsTemp.push(subResult.success);
                }
              } /*  else {
                     } */
            }
          }

          const {
            createdAt,
            createdBy,
            updatedAt,
            updatedBy,
            notice,
            ...restItem
          } = item;

          result = await editAPI(id, restItem);
          if (result.success === false) messageResult = result;
          resultOfActionsTemp.push(result.success);
        } else {
          result = await createAPI(id, item);
          if (result.success === false) messageResult = result;
          resultOfActionsTemp.push(result.success);

          if (
            entityType === "allExpenseItems" &&
            item.subExpenseItems.length > 0
          ) {
            let filteredItems = item.subExpenseItems.filter((el) => el.name);

            //item.subExpenseItems.filter(el => el.name).forEach(async subItem => {
            for (let subItem of filteredItems) {
              subResult = await createNoticeSubExpenseItems(
                id,
                result.data.id,
                subItem
              );
              if (subResult.success === false) messageResult = subResult;
              resultOfActionsTemp.push(subResult.success);
            }
          }
        }
      } else {
        messageResult = {
          success: false,
          data: { validation: [`${entityType} ${translate('common.error.notsaved', 'non salvati')}`] },
        };
        resultOfActionsTemp.push(false);
        return;
      }
    }

    messageResult = messageResult ?? { success: true };
    return { resultOfActionsTemp, messageResult };
  };

  const handleDelete = async () => {
    const deleteResult = await deleteNotice(id);

    if (deleteResult.success) {
      handleStatusBar({
        success: true,
        message: translate('common.message.deleted', 'Elemento eliminato correttamente'),
      });

      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar(deleteResult);
    }
  };

  const addElement = (
    section,
    typeArray,
    setTypeArray,
    fakeId,
    parentIndex
  ) => {
    //'subExpenseItems', allExpenseItems , setAllExpenseItems, 18, item-0
    let tempTypeArray = [...typeArray];

    let objTypeArray = {};

    switch (section) {
      case "docsOfAccountabilities":
        objTypeArray = { name: "", description: "", noticeId: notice.id };
        break;
      case "targets":
        objTypeArray = { name: "", uom: "", noticeId: notice.id };
        break;
      case "subExpenseItems":
        objTypeArray = {
          name: "",
          description: "",
          noticeId: notice.id,
          fakeExpenseItemId: fakeId,
        };
        break;
      case "allExpenseItems":
        objTypeArray = {
          name: "",
          description: "",
          noticeId: notice.id,
          fakeId: fakeId,
          subExpenseItems: [
            { name: "", description: "", fakeExpenseItemId: fakeId },
          ],
        };
        break;
      case "engagementRules":
        objTypeArray = { name: "", content: "", noticeId: notice.id };
        break;
      default:
        objTypeArray = {};
    }

    if (section === "subExpenseItems") {
      tempTypeArray[parentIndex].subExpenseItems.push(objTypeArray);
    } else {
      tempTypeArray.push(objTypeArray);
    }

    setTypeArray(tempTypeArray);
  };

  const removeElement = (
    typeArray,
    setTypeArray,
    index,
    subIndex,
    type,
    fakeId,
    parentId,
    subId
  ) => {
    let tempTypeArray = [...typeArray];

    if (type === "subExpenseItems") {
      tempTypeArray[index].subExpenseItems.splice(subIndex, 1);
      if (tempTypeArray[index].subExpenseItems.length === 0)
        tempTypeArray[index].subExpenseItems.push({
          name: "",
          description: "",
          fakeExpenseItemId: fakeId,
        });

      if (subId) {
        setDeletedSubExpenseItems((prevState) => [
          ...prevState,
          ...[{ parentId: parentId, subId: subId }],
        ]);
      }
    } else {
      tempTypeArray.splice(index, 1);
    }

    setTypeArray(tempTypeArray);
  };

  const expenseItemsRows = (allExpenseItems, setAllExpenseItems) => {
    let rows = [];
    let isThisRowFilled;
    let parentIndex;

    if (allExpenseItems) {
      if (allExpenseItems.length === 0) {
        allExpenseItems = [
          {
            name: "",
            description: "",
            noticeId: notice.id,
            subExpenseItems: [{ name: "", description: "" }],
          },
        ];
      }

      //let lastIndex = allExpenseItems.length - 1;

      rows = allExpenseItems
        .sort((valueA, valueB) => valueA.position - valueB.position)
        .map((el, i) => {
          el.fakeId = el.id ?? "item-" + i;
          isThisRowFilled = el.name ? true : false;
          parentIndex = i;

          return (
            <Fragment key={"item-" + i}>
              <Row className="align-items-center mb-3">
                <Col className="col-auto ps-1">
                  {!isUsed && (
                    <Button
                      variant="link"
                      className="me-1"
                      style={{
                        visibility:
                          allExpenseItems.length !== 1 ? "visible" : "hidden",
                      }}
                      onClick={() =>
                        removeElement(allExpenseItems, setAllExpenseItems, i)
                      }
                    >
                      <IcoMinusCircled className="red-color ico-small indicators" />
                    </Button>
                  )}
                </Col>
                <Col className="col-6">
                  <Form.Control
                    disabled={isUsed}
                    className={el.name ? "fw-bold" : ""}
                    type="text"
                    name="name"
                    placeholder={translate('common.placeholder.expenseitem', 'voce di spesa')}
                    value={el.name ?? ""}
                    onChange={(e) =>
                      onChange(
                        e,
                        "allExpenseItems",
                        setAllExpenseItems,
                        undefined,
                        i,
                        allExpenseItems
                      )
                    }
                  />
                  {/* index padre:{i} */}
                </Col>
              </Row>
              {el.subExpenseItems &&
                el.subExpenseItems
                  .sort((valueA, valueB) => valueA.position - valueB.position)
                  .map((subExpenseItem, index) => {
                    //subExpenseItem.fakeExpenseItemId = el.id ?? 'item-' + i;
                    const isThisSubRowFilled = subExpenseItem.name
                      ? true
                      : false;
                    let lastIndexSub = el.subExpenseItems.length - 1;

                    return (
                      <Row
                        className="align-items-center mb-3"
                        key={"subItem-" + index}
                      >
                        <Col className="col-1"></Col>
                        <Col className="col-6">
                          <Form.Control
                            disabled={isUsed}
                            type="text"
                            name="name"
                            placeholder={translate('common.placeholder.subexpenseitem', 'sottovoce di spesa')}
                            value={subExpenseItem.name ?? ""}
                            onChange={(e) =>
                              onChange(
                                e,
                                "subExpenseItems",
                                setAllExpenseItems,
                                undefined,
                                index,
                                el.subExpenseItems,
                                i
                              )
                            }
                          />
                          {/* index figlio:{index} */}
                        </Col>
                        {!isUsed && (
                          <Col className="ps-1">
                            {index === lastIndexSub ? (
                              <Button
                                variant="link"
                                className="me-2"
                                onClick={() =>
                                  addElement(
                                    "subExpenseItems",
                                    allExpenseItems,
                                    setAllExpenseItems,
                                    el.id ?? "item-" + i,
                                    i
                                  )
                                }
                                disabled={!isThisSubRowFilled}
                              >
                                <IcoPlus className="second-color ico-small indicators" />
                              </Button>
                            ) : null}
                            <Button
                              variant="link"
                              className="me-1"
                              onClick={() =>
                                removeElement(
                                  allExpenseItems,
                                  setAllExpenseItems,
                                  i,
                                  index,
                                  "subExpenseItems",
                                  el.id ?? "item-" + i,
                                  el.id,
                                  subExpenseItem.id
                                )
                              }
                            >
                              <IcoMinusCircled className="red-color ico-small indicators" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    );
                  })}
            </Fragment>
          );
        });
    }

    return (
      <>
        {rows}
        {!isUsed && (
          <Button
            className="mt-4 ms-5"
            variant="primary"
            onClick={() =>
              addElement(
                "allExpenseItems",
                allExpenseItems,
                setAllExpenseItems,
                "item-" + (parentIndex + 1),
                parentIndex
              )
            }
            disabled={!isThisRowFilled}
          >
            <IcoPlus className="light-color ico-small plus new-button" />
            <span className="d-md-inline">&nbsp;<OETrans code='common.addexpenseitem' fallback={'Aggiungi voce di spesa'}/></span>
          </Button>
        )}
      </>
    );
  };

  const engagementRulesRows = (
    engagementRules,
    setExpenseItems,
    expenseType
  ) => {
    let rows = [];

    if (engagementRules) {
      if (engagementRules.length === 0) {
        engagementRules = [{ name: "", content: "", noticeId: notice.id }];
      }

      //console.log('engagementRules', engagementRules);
      let lastIndex = engagementRules.length - 1;

      rows = engagementRules.map((el, i) => {
        const isThisRowFilled = el.name ? true : false;

        return (
          <Row key={"rule-" + i} style={{ marginBottom: "40px" }}>
            <Col className="col-4">
              <Form.Control
                type="text"
                name="name"
                placeholder={translate('common.placeholder.engagementrulename', 'nome')}
                value={el.name ?? ""}
                onChange={(e) =>
                  onChange(
                    e,
                    "engagementRules",
                    setEngagementRules,
                    undefined,
                    i,
                    engagementRules
                  )
                }
              />
            </Col>
            <Col className="col-6">
              <Form.Control
                type="text"
                name="content"
                placeholder={translate('common.placeholder.engagementruledescription', 'descrizione (opzionale)')}
                value={el.content ?? ""}
                onChange={(e) =>
                  onChange(
                    e,
                    "engagementRules",
                    setEngagementRules,
                    undefined,
                    i,
                    engagementRules
                  )
                }
                disabled={!el.name}
              />
            </Col>
            <Col className="col-2">
              {engagementRules.length !== 1 ? (
                <Button
                  variant="link"
                  className="me-3"
                  onClick={() =>
                    removeElement(engagementRules, setExpenseItems, i)
                  }
                >
                  <IcoMinusCircled className="red-color ico-small indicators" />
                </Button>
              ) : null}
              {i === lastIndex ? (
                <Button
                  variant="link"
                  onClick={() =>
                    addElement(
                      "engagementRules",
                      engagementRules,
                      setExpenseItems,
                      expenseType
                    )
                  }
                  disabled={!isThisRowFilled}
                >
                  <IcoPlus className="second-color ico-small indicators" />
                </Button>
              ) : null}
            </Col>
          </Row>
        );
      });
    }

    return rows;
  };

  const docsOfAccountabilityRows = (
    docsOfAccountability,
    setDocsOfAccountability
  ) => {
    let rows = [];

    if (docsOfAccountability) {
      if (docsOfAccountability.length === 0) {
        docsOfAccountability = [
          { name: "", description: "", noticeId: notice.id },
        ];
      }

      let lastIndex = docsOfAccountability.length - 1;

      rows = docsOfAccountability.map((el, i) => {
        const isThisRowFilled = el.name && el.description ? true : false;

        return (
          <Row
            style={{ marginBottom: "40px" }}
            key={"docsOfAccountability-" + i}
          >
            <Col className="col-6">
              <Form.Control
                type="text"
                name="name"
                placeholder={translate('common.placeholder.documentname', 'nome documento')}
                value={el.name ?? ""}
                onChange={(e) =>
                  onChange(
                    e,
                    "docsOfAccountabilities",
                    setDocsOfAccountability,
                    undefined,
                    i,
                    docsOfAccountability
                  )
                }
                //disabled={isUsed} ABILITATO PER PRESENTAZIONE
              />
            </Col>
            <Col className="col-4">
              <Form.Control
                className="custom-disabled"
                type="text"
                name="description"
                placeholder={translate('common.placeholder.documentdescription', 'descrizione documento')}
                value={el.description ?? ""}
                onChange={(e) =>
                  onChange(
                    e,
                    "docsOfAccountabilities",
                    setDocsOfAccountability,
                    undefined,
                    i,
                    docsOfAccountability
                  )
                }
                //disabled={isUsed} ABILITATO PER PRESENTAZIONE
              />
            </Col>
            {true /* !isUsed && */ && ( //ABILITATO PER PRESENTAZIONE
              <Col className="col-2">
                {i === lastIndex ? (
                  <Button
                    variant="link"
                    className="me-3"
                    onClick={() =>
                      addElement(
                        "docsOfAccountabilities",
                        docsOfAccountability,
                        setDocsOfAccountability
                      )
                    }
                    disabled={!isThisRowFilled && (!el.name || !el.description)}
                  >
                    <IcoPlus className="second-color ico-small indicators" />
                  </Button>
                ) : null}
                {docsOfAccountability.length !== 1 ? (
                  <Button
                    disabled={isUsed}
                    variant="link"
                    onClick={() =>
                      removeElement(
                        docsOfAccountability,
                        setDocsOfAccountability,
                        i
                      )
                    }
                  >
                    <IcoMinusCircled className="red-color ico-small indicators" />
                  </Button>
                ) : null}
              </Col>
            )}
          </Row>
        );
      });
    }

    return rows;
  };

  const targetsRows = (targets, setTargets) => {
    let rows = [];

    if (targets) {
      if (targets.length === 0) {
        targets = [{ name: "", uom: "", noticeId: notice.id }];
      }

      let lastIndex = targets.length - 1;

      rows = targets.map((el, i) => {
        const isThisRowFilled = el.name && el.uom ? true : false;

        return (
          <Row key={"target-" + i} style={{ marginBottom: "40px" }}>
            <Col className="col-6">
              <Form.Control
                type="text"
                name="name"
                placeholder={translate('common.placeholder.target', 'obiettivo')}
                value={el.name ?? ""}
                onChange={(e) =>
                  onChange(e, "targets", setTargets, undefined, i, targets)
                }
                disabled={isUsed}
              />
            </Col>
            <Col className="col-4">
              <Form.Control
                className="custom-disabled"
                type="text"
                name="uom"
                placeholder={translate('common.placeholder.uom', 'udm') + '*'}
                value={el.uom ?? ""}
                onChange={(e) =>
                  onChange(e, "targets", setTargets, undefined, i, targets)
                }
                disabled={isUsed}
              />
            </Col>
            {!isUsed && (
              <Col className="col-2">
                {i === lastIndex ? (
                  <Button
                    variant="link"
                    className="me-3"
                    onClick={() => addElement("targets", targets, setTargets)}
                    disabled={!isThisRowFilled && (!el.name || !el.uom)}
                  >
                    <IcoPlus className="second-color ico-small indicators" />
                  </Button>
                ) : null}
                {targets.length !== 1 ? (
                  <Button
                    disabled={isUsed}
                    variant="link"
                    onClick={() => removeElement(targets, setTargets, i)}
                  >
                    <IcoMinusCircled className="red-color ico-small indicators" />
                  </Button>
                ) : null}
              </Col>
            )}
          </Row>
        );
      });
    }

    return rows;
  };

  const editTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.edit' fallback={'Modifica'}/></Tooltip>;
  const deleteTooltip = (props) => <Tooltip {...props}>{isUsed ? "Bando in uso" : <OETrans code='common.tooltip.delete' fallback={'Elimina'}/>}</Tooltip>;

  const taxValue = (taxonomyValue, options) => {
    if (options) {
      const selectedValue = options.filter(
        (opt) => opt.value === taxonomyValue
      );
      return selectedValue;
    } else {
      return taxonomyValue ?? "";
    }
  };

  const renderTooltip = (props) => {
    let message = "";

    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id="button-tooltip" {...props}>
        {message}
      </Tooltip>
    );
  };

   const handleDownload = async (e, downloadDocument, isLoading, setIsLoading) => {
      setSbShow(<></>);
      setIsLoading(true);

      downloadDocument(id).then(result => {
         //console.log('result', result);
      })
      .finally(() => {
         setIsLoading(false);
      });
   };

  return (
    <div>
      <Button
        variant="outline-info"
        className="py-1 me-3"
        onClick={() => handleShowModalTab(setShowModalEdit, "docs")}
      >
        <OETrans code='common.documents' fallback={'Documenti'}/>
      </Button>

      <OverlayTrigger 
         placement='top' 
         overlay={<Tooltip>
            <OETrans code={isLoadingReport ? 'common.downloading' : 'common.downloadreport'} fallback={isLoadingReport ? 'Download in corso' : 'Scarica report'} />
            {isLoadingReport && '...'}
         </Tooltip>}
      >
         <Button variant='link' className='me-3' onClick={(e) => handleDownload(e, downloadControlsReport, isLoadingReport, setIsLoadingReport)}>
            {isLoadingReport ? (
               <Spinner
                  as="span"
                  style={{ width: "25px", height: "25px" }}
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  className='align-bottom'
               />
            ) : 
            (<IcoDownloadFile className='second-color ico-small massive-upload align-bottom' />)
            }
         </Button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={editTooltip}>
        <Button
          variant="link"
          className="me-3"
          onClick={() => handleShowModal(setShowModalEdit)}
        >
          <IcoEdit className="second-color ico-small edit" />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={deleteTooltip}>
        <span>
          <Button
            variant="link"
            className="me-0"
            onClick={() => handleShowModal(setShowModalDelete)}
            disabled={isUsed}
          >
            <IcoCanc className="second-color ico-small cancel" />
          </Button>
        </span>
      </OverlayTrigger>

      {showModalEdit && (
        <Modal
          size="xl"
          dialogClassName="modal-75w"
          className="modal-with-nav"
          show={showModalEdit}
          onHide={() => handleCloseModal(setShowModalEdit)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><OETrans code='common.notices.editnotice' fallback={'Modifica bando'}/></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              className="mb-4"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
              variant='pills'
              fill
            >
              {/* Anagrafica*/}
              <Tab eventKey="registry" title={translate('common.registry', 'Anagrafica')}>
                <Form onSubmit={handleEdit} id="form-new-notice">
                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='notices.call' fallback={'Avviso/Call'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="call"
                          value={notice.call ?? ""}
                          onChange={(e) => onChange(e, "call")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-3">
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='notices.publishedAt' fallback={'Pubblicazione'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="publishedAt"
                          value={
                            notice.publishedAt
                              ? moment(notice.publishedAt).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) => onChange(e, "publishedAt")}
                          isInvalid={
                            new Date(notice.publishedAt).getTime() >
                            new Date(notice.expiresAt).getTime()
                          }
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="position-absolute"
                        >
                           {translate('common.error.published', 'la pubblicazione deve essere antecedente alla scadenza').toLowerCase()}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-3">
                      <Form.Group as={Col}>
                        <Form.Label className="mb-3">
                           <OETrans code='notices.expiresAt' fallback={'Scadenza'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="expiresAt"
                          value={
                            notice.expiresAt
                              ? moment(notice.expiresAt).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) => onChange(e, "expiresAt")}
                          isInvalid={
                            new Date(notice.publishedAt).getTime() >
                            new Date(notice.expiresAt).getTime()
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='notices.name' fallback={'Bando'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="name"
                          value={notice.name}
                          onChange={(e) => onChange(e, "name")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='notices.fundId' fallback={'Fondo'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="fundId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedFund}
                          options={optionsFunds}
                          onChange={(e) =>
                            onChange(e, "fundId", setSelectedFund)
                          }
                          isDisabled={isUsed}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='notices.instrumentId' fallback={'Strumento'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="instrumentId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedInstrument}
                          options={optionsInstruments}
                          onChange={(e) =>
                            onChange(e, "instrumentId", setSelectedInstrument)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='notices.institutionId' fallback={'Ente'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="institutionId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedInstitution}
                          options={optionsInstitutions}
                          onChange={(e) =>
                            onChange(e, "institutionId", setSelectedInstitution)
                          }
                          isDisabled={isUsed}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                     <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='common.setchecklist' fallback={'Set Domande'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                           components={{
                           IndicatorSeparator: () => null,
                           }}
                           name="questionsetId"
                           placeholder={translate('common.placeholder.select', 'Seleziona...')}
                           value={selectedQuestionSet}
                           options={optionsQuestionSet}
                           className="react-select-container"
                           classNamePrefix="react-select"
                           onChange={(e) =>
                           onChange(e, "questionsetId", setSelectedQuestionSet)
                           }
                           isDisabled={isUsed}
                        />
                     </Form.Group>
                     </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='notices.contactpersons' fallback={'Referente UDM/RUP'}/></Form.Label>
                        <FloatingLabel label={translate('common.placeholder.contactpersons.firstname', 'Nome')}>
                          <Form.Control
                            type="text"
                            name="firstName"
                            placeholder={translate('common.placeholder.contactpersons.firstname', 'Nome')}
                            value={contactPersons[0]?.firstName ?? ""}
                            onChange={(e) =>
                              onChange(
                                e,
                                "contactPersons",
                                setContactPersons,
                                undefined,
                                0,
                                contactPersons
                              )
                            }
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label>&nbsp;</Form.Label>
                        <FloatingLabel label={translate('common.placeholder.contactpersons.lastname', 'Cognome')}>
                          <Form.Control
                            type="text"
                            name="lastName"
                            placeholder={translate('common.placeholder.contactpersons.lastname', 'Cognome')}
                            value={contactPersons[0]?.lastName ?? ""}
                            onChange={(e) =>
                              onChange(
                                e,
                                "contactPersons",
                                setContactPersons,
                                undefined,
                                0,
                                contactPersons
                              )
                            }
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label>&nbsp;</Form.Label>
                        <FloatingLabel label={translate('common.placeholder.contactpersons.email', 'Email')}>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder={translate('common.placeholder.contactpersons.email', 'email')}
                            value={contactPersons[0]?.email ?? ""}
                            onChange={(e) =>
                              onChange(
                                e,
                                "contactPersons",
                                setContactPersons,
                                undefined,
                                0,
                                contactPersons
                              )
                            }
                            onBlur={(e) =>
                              onBlur(
                                e.target.value,
                                emailRegex,
                                setEmailInvalid
                              )
                            }
                            isInvalid={emailInvalid}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="ps-1 position-absolute"
                          >
                            {translate('common.error.email', 'email non conforme').toLowerCase()}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label>&nbsp;</Form.Label>
                        <FloatingLabel label={translate('common.placeholder.contactpersons.phone', 'Recapito telefonico')}>
                          <Form.Control
                            type="text"
                            name="phone"
                            placeholder={translate('common.placeholder.contactpersons.phone', 'recapito telefonico')}
                            value={contactPersons[0]?.phone ?? ""}
                            onChange={(e) =>
                              onChange(
                                e,
                                "contactPersons",
                                setContactPersons,
                                undefined,
                                0,
                                contactPersons
                              )
                            }
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                {sbShow}
              </Tab>

              {/* Tassonomie & Obiettivi */}
              <Tab
                eventKey="taxonomyInstances"
                title={translate('common.taxonomiestargets', 'Tassonomie & Obiettivi')}
              >
                <Form onSubmit={handleEdit} id="form-new-notice">
                  {taxonomyInstances.length > 0 && (
                    <>
                      {
                        // Dividi taxonomyInstances in gruppi di 3
                        taxonomyInstances
                          .sort(
                            (a, b) => a.taxonomy.position - b.taxonomy.position
                          )
                          .reduce((acc, taxonomy, i) => {
                            if (i % 3 === 0) acc.push([]);
                            acc[acc.length - 1].push(taxonomy);
                            return acc;
                          }, [])
                          .map((group, groupIndex) => (
                            // Renderizza ogni gruppo di 3 tassonomie in una Row
                            <Row
                              key={groupIndex}
                              style={{ marginBottom: "40px" }}
                            >
                              {group.map((taxonomy) => {
                                const fundTax = fundTaxonomies.filter(
                                  (fundTax) =>
                                    fundTax.code === taxonomy.fundTaxonomyCode
                                );
                                const label = fundTax
                                  .map((el) => el.name)
                                  .pop();
                                let options = [];

                                if (
                                  Object.keys(currentPnrrTaxonomies).length > 0
                                ) {
                                  options = currentPnrrTaxonomies[
                                    label.toLowerCase()
                                  ]?.map((pnrrTax) => ({
                                    value: pnrrTax.code,
                                    label: `${
                                      pnrrTax.code === pnrrTax.description
                                        ? ""
                                        : `${pnrrTax.code} - `
                                    }${pnrrTax.description}`,
                                  }));
                                }

                                return (
                                  <Col key={taxonomy.id}>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        {fundTax.map((el) => el.name)} {/* traduzione non disponibile */}
                                      </Form.Label>
                                      {notice.fundId === 1 &&
                                      taxonomy.fundTaxonomyCode !==
                                        "PNRRAMM" ? (
                                        /* se fondo=PNRR ed è il campo non è Amn. Titolare */
                                        <Select
                                          components={{
                                            IndicatorSeparator: () => null,
                                          }}
                                          className="react-select-container"
                                          classNamePrefix="react-select"
                                          name={taxonomy.fundTaxonomyCode}
                                          isDisabled={isUsed}
                                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                          value={taxValue(
                                            taxonomy.value,
                                            options
                                          )}
                                          options={options}
                                          onChange={(e) =>
                                            onChange(
                                              e,
                                              "taxonomyinstances",
                                              setTaxonomyInstances,
                                              taxonomy.fundTaxonomyCode
                                            )
                                          }
                                        />
                                      ) : (
                                        <Form.Control
                                          type={
                                            fundTax
                                              .map((el) => el.valueType)
                                              .pop() === "string"
                                              ? "text"
                                              : "number"
                                          }
                                          name={taxonomy.fundTaxonomyCode}
                                          value={taxValue(taxonomy.value)}
                                          onChange={(e) =>
                                            onChange(
                                              e,
                                              "taxonomyinstances",
                                              setTaxonomyInstances,
                                              taxonomy.fundTaxonomyCode
                                            )
                                          }
                                          disabled={
                                            (notice.fundId === 1 &&
                                              taxonomy.fundTaxonomyCode ===
                                                "PNRRAMM") ||
                                            isUsed
                                          }
                                        />
                                      )}
                                    </Form.Group>
                                  </Col>
                                );
                              })}
                            </Row>
                          ))
                      }
                    </>
                  )}
                  <Row style={{ marginBottom: "40px" }}>
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='common.targets' fallback={'Obiettivi'}/></Form.Label>
                      {targetsRows(targets, setTargets)}
                    </Form.Group>
                  </Row>

                  {sbShow}
                </Form>
              </Tab>

              {/* Documenti del Rendiconto */}
              <Tab
                eventKey="docsOfAccountability"
                title={translate('common.reportdocuments', 'Documenti del Rendiconto')}
              >
                <Form onSubmit={handleEdit} id="form-new-notice">
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='common.documents' fallback={'Documenti'}/></Form.Label>
                      {docsOfAccountabilityRows(
                        docsOfAccountability,
                        setDocsOfAccountability
                      )}
                    </Form.Group>
                  </Row>
                  {sbShow}
                </Form>
              </Tab>

              {/* Voci di spesa */}
              <Tab eventKey="expenseItems" title={translate('common.expenseitems', 'Voci di spesa')}>
                <Form onSubmit={handleEdit} id="form-new-notice">
                  <Row style={{ marginBottom: "40px" }}>
                    <Col>
                      <Row>
                        <Form.Group as={Col}>
                          <Form.Label>
                            <OETrans code='common.expenseitems' fallback={'Voci di spesa'}/>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 200, hide: 400 }}
                              overlay={renderTooltip}
                              popperConfig={{
                                testObj: translate('common.expenseitems', 'Le sottovoci di spesa devono essere univoche. In caso di nome uguale, inserire tra parentesi la macro voce di spesa. Es. Altro (Altri beni ad utilità pluriennale)', 'description'),
                              }}
                            >
                              <TooltipInfoIcon
                                className="tooltip-ico"
                                style={{ width: "1rem" }}
                              />
                            </OverlayTrigger>
                          </Form.Label>
                          {expenseItemsRows(
                            allExpenseItems,
                            setAllExpenseItems
                          )}
                        </Form.Group>
                      </Row>
                    </Col>
                  </Row>
                </Form>
                {sbShow}
              </Tab>

              {/* Regole d'ingaggio */}
              <Tab eventKey="engagementRules" title={translate('common.engagementrules', 'Regole d’ingaggio')}>
                <Form onSubmit={handleEdit} id="form-new-notice">
                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='notices.objectives' fallback={'Obiettivi'}/></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="objectives"
                          value={notice.objectives ?? ""}
                          onChange={(e) => onChange(e, "objectives")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='notices.timeframe' fallback={'Data di avvio/ultimazione evento'}/>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="timeframe"
                          value={notice.timeframe ?? ""}
                          onChange={(e) => onChange(e, "timeframe")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='notices.managingSystem' fallback={'Sistema informatico di monitoraggio e rendicontazione'}/>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="managingSystem"
                          value={notice.managingSystem ?? ""}
                          onChange={(e) => onChange(e, "managingSystem")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='notices.compounding' fallback={'Possibilità di cumulo'}/></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="compounding"
                          value={notice.compounding ?? ""}
                          onChange={(e) => onChange(e, "compounding")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='notices.changeManagement' fallback={'Gestione varianti'}/></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="changeManagement"
                          value={notice.changeManagement ?? ""}
                          onChange={(e) => onChange(e, "changeManagement")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='notices.disbursement' fallback={'Richieste di erogazione'}/></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="disbursement"
                          value={notice.disbursement ?? ""}
                          onChange={(e) => onChange(e, "disbursement")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='notices.admissibleCosts' fallback={'Costi ammissibili'}/></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="admissibleCosts"
                          value={notice.admissibleCosts ?? ""}
                          onChange={(e) => onChange(e, "admissibleCosts")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='notices.engagementrules.otheritems' fallback={'Altre voci'}/></Form.Label>
                      {engagementRulesRows(engagementRules, setEngagementRules)}
                    </Form.Group>
                  </Row>
                </Form>
                {sbShow}
              </Tab>

              {/* Condizionalità */}
              <Tab
                eventKey="conditionality"
                title={translate('common.conditionality', 'Condizionalità')}
              >
                <Form onSubmit={handleEdit} id="form-new-notice">
                  <Row style={{ marginBottom: "40px" }}>
                    <Form.Group as={Col}>
                      <Form.Check
                        disabled={isUsed}
                        inline
                        type="checkbox"
                        name="environmentalTagging"
                        defaultChecked={notice.environmentalTagging}
                        onClick={(e) => onChange(e, "environmentalTagging")}
                      />
                      <Form.Label><OETrans code='notices.environmentalTagging' fallback={'Tagging Climatico'}/></Form.Label>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Check
                        disabled={isUsed}
                        inline
                        type="checkbox"
                        name="digitalTagging"
                        defaultChecked={notice.digitalTagging}
                        onClick={(e) => onChange(e, "digitalTagging")}
                      />
                      <Form.Label><OETrans code='notices.digitalTagging' fallback={'Tagging digitale'}/></Form.Label>
                    </Form.Group>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Form.Group as={Col}>
                      <Form.Check
                        disabled={isUsed}
                        inline
                        type="checkbox"
                        name="genderEquality"
                        defaultChecked={notice.genderEquality}
                        onClick={(e) => onChange(e, "genderEquality")}
                      />
                      <Form.Label>
                        <OETrans code='notices.genderEquality' fallback={'Parità di genere e politiche giovanili'}/>
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Check
                        disabled={isUsed}
                        inline
                        type="checkbox"
                        name="dnsh"
                        defaultChecked={notice.dnsh}
                        onClick={(e) => onChange(e, "dnsh")}
                      />
                      <Form.Label><OETrans code='notices.dnsh' fallback={'DNSH'}/></Form.Label>
                    </Form.Group>
                  </Row>
                </Form>
                {sbShow}
              </Tab>

              {/* Documenti */}
              <Tab eventKey="docs" title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                <UploadDocument entity="notices" entityId={notice.id} />
              </Tab>
            </Tabs>

            {(activeKey === "registry" || activeKey === "docs") && (
              <Row>
                <Col className="col-12">
                  <span className="label">
                    <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                  </span>
                </Col>
              </Row>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleCloseModal(setShowModalEdit)}
            >
              <OETrans code='common.cancel' fallback={'Annulla'}/>
            </Button>
            <Button
              variant="primary"
              onClick={(e) => handleEdit(e)}
              disabled={activeKey === "docs"}
            >
              <OETrans code='common.edit' fallback={'Modifica'}/>
              {showSpinner && (
                <Spinner
                  as="span"
                  className="ms-2"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showModalDelete}
        onHide={() => handleCloseModal(setShowModalDelete)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <span><OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} /></span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalDelete)}
          >
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <OETrans code='common.delete' fallback={'Elimina'}/>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalWarning}
        onHide={() => setShowModalWarning(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OETrans 
            code='common.warning.editingtargetsexpenseitems' 
            fallback={'Le modifiche agli obiettivi e alle voci di spesa di un bando in uso non avranno effetto sui progetti esistenti'}
          />.
          <br />
          <br />
          <OETrans code='common.warning.wantcontinue' fallback={'Sei sicuro di voler continuare?'}/>     
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => checkConfirmation(false)}>
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button variant="primary" onClick={() => checkConfirmation(true)}>
            <OETrans code='common.confirm' fallback={'Conferma'}/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ActionBtnRendererNotices;
