import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
  Spinner,
} from 'react-bootstrap';
import { getTenderCigs } from '@lib/api';
import CheckList from 'components/checklist/checkList';
import UploadDocument from 'components/form/upload/uploadDocuments';
import MassiveUpload from 'components/form/upload/massiveUpload';
import { formatToDecimals } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const TendersSummaryModal = ({
  data,
  setRefresh,
  optionsProcedures,
  optionsCriteria,
  optionsClassifications,
  optionsGazettePubIT,
  optionsGazettePubUE,
  optionsDedicated,
  showButtons = true,
  modalIsVisible,
  setModalIsVisible,
  spinnerVisible,
  setModalData,
  isSummary,
}) => {
  const id = data.id;
  const { isOe, userRole } = useContext(LevelContext);
  const [tender, setTender] = useState({});
  const [cigs, setCigs] = useState([]);

  // TODO: remove originCigs if not used
  const [originCigs, setOriginCigs] = useState([]); // eslint-disable-line
  const [selectedProcedure, setSelectedProcedure] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState([]);
  const [selectedGazettePubIT, setSelectedGazettePubIT] = useState([]);
  const [selectedGazettePubUE, setSelectedGazettePubUE] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [selectedDedicated, setSelectedDedicated] = useState([]);
  const [sbShow, setSbShow] = useState(<></>);
  const [activeKey, setActiveKey] = useState('registry');
  const [isEdited, setIsEdited] = useState(false);
  //console.log('selectedCompany', selectedCompany);
  //console.log('selectedProcedure', selectedProcedure);
  //console.log('originCigs', originCigs);
  //console.log('cigs', cigs);

  useEffect(() => {
    setTender(data);

    if (modalIsVisible && id) {
      getTenderCigs(id).then((retrievedCigs) => {
        setCigs(retrievedCigs.data);
        setOriginCigs(retrievedCigs.data);
      });
    }
  }, [data, id, modalIsVisible]);

  useEffect(() => {
    setSelectedProcedure(
      optionsProcedures
        .filter((option) => option.value === tender.assignmentProcedureCode)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedClassification(
      optionsClassifications
        .filter((option) => option.value === tender.tenderClassification)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedGazettePubIT(
      optionsGazettePubIT
        .filter((option) => +option.value === tender.officialGazettePubItaly)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedGazettePubUE(
      optionsGazettePubUE
        .filter((option) => +option.value === tender.officialGazettePubEurope)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedCriteria(
      optionsCriteria
        .filter((option) => option.value === tender.assignmentCriteriaCode)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedDedicated(
      optionsDedicated
        .filter((option) => +option.value === tender.dedicated)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );
  }, [
    optionsClassifications,
    optionsCriteria,
    optionsDedicated,
    optionsGazettePubIT,
    optionsGazettePubUE,
    optionsProcedures,
    tender,
  ]);

  const handleCloseModal = (setShowModal) => {
    if (isEdited) {
      setRefresh(true);
      setIsEdited(false);
    }
    setModalData([]);
    setSbShow(<></>);
    setActiveKey('registry');
    // setRefresh(true);
    setShowModal(false);
  };
  // const handleShowModal = (setShowModal) => setShowModal(true);

  // const handleStatusBar = (status, message, timeout) => {
  //   setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  // };

  const cigsRows = (cigs) => {
    let rows = [
      <Row key={'cig-00'}>
        <Col className='col-6'>
          <Form.Label>Codice</Form.Label>
        </Col>
        <Col className='col-4'>
          <Form.Label>Lotto</Form.Label>
        </Col>
      </Row>,
    ];

    if (cigs) {
      if (cigs.length === 0) {
        cigs = [{ cig: '', lot: '', tenderId: tender.id }];
      }

      rows.push(
        cigs.map((el, i) => {
          return (
            <Row key={'cig-' + i} style={{ marginBottom: '40px' }}>
              <Col className='col-6'>
                <Form.Text as='p'>{el.cig ?? 'N.D.'}</Form.Text>
              </Col>
              <Col className='col-4'>
                <Form.Text as='p'>{el.lot ?? 'N.D.'}</Form.Text>
              </Col>
            </Row>
          );
        })
      );
    }

    return rows;
  };

  return (
    <div>
      {modalIsVisible && (
        <Modal
          size='xl'
          className='modal-with-nav'
          show={modalIsVisible}
          onHide={() => handleCloseModal(setModalIsVisible)}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Visualizza gara</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {spinnerVisible ? (
              <Row>
                <Col className='d-flex justify-content-center align-items-center'>
                  <Spinner animation='border' role='status' variant='dark'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : (
              <Tabs
                className='mb-4'
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k)}
                fill
                variant='pills'
               >
                {/* Anagrafica */}
                <Tab eventKey='registry' title={translate('common.registry', 'Anagrafica')}>
                  <Form id='form-new-tender'>
                    <Row className='mb-3' style={{ marginBottom: '40px' }}>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Società &nbsp;</Form.Label>
                          <Form.Text as='p'>{tender?.company?.name ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>N. gara &nbsp;</Form.Label>
                          <Form.Text as='p'>{tender?.tenderNumber ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Classificazione gara &nbsp;</Form.Label>
                          <Form.Text as='p'>
                            {selectedClassification?.[0]?.label ?? 'N.D.'}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: '40px' }}>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Tipologia procedura &nbsp;</Form.Label>
                          <Form.Text as='p'>{selectedProcedure?.[0]?.label ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Sistema di qualificazione &nbsp;</Form.Label>
                          <Form.Text as='p'>{tender?.qualificationSystem ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>
                            Criterio di aggiudicazione &nbsp;
                          </Form.Label>
                          <Form.Text as='p'>{selectedCriteria?.[0]?.label ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: '40px' }}>
                      <Col className='col-3'>
                        <Form.Group as={Col} >
                          <Form.Label>Data indizione</Form.Label>
                          <Form.Text as='p'>
                            {tender?.callDate
                              ? moment(tender.callDate).format('YYYY-MM-DD')
                              : 'N.D.'}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-3'>
                        <Form.Group as={Col}>
                          <Form.Label>Data avviso aggiudicazione</Form.Label>
                          <Form.Text as='p'>
                            {tender?.assignmentDate
                              ? moment(tender.assignmentDate).format('YYYY-MM-DD')
                              : 'N.D.'}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-3'>
                        <Form.Group as={Col}>
                          <Form.Label>
                            Pubblicazione gazzetta (GURI) &nbsp;
                          </Form.Label>
                          <Form.Text as='p'>{selectedGazettePubIT?.[0]?.label ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-3'>
                        <Form.Group as={Col}>
                          <Form.Label>
                            Pubblicazione gazzetta (GUUE) &nbsp;
                          </Form.Label>
                          <Form.Text as='p'>{selectedGazettePubUE?.[0]?.label ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: '40px' }}>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Importo base d'asta &nbsp;</Form.Label>
                          <Form.Text as='p'>
                            {tender?.auctionImport
                              ? `${formatToDecimals(tender?.auctionImport)} €`
                              : 'N.D.'}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Importo complessivo gara &nbsp;</Form.Label>
                          <Form.Text as='p'>
                            {tender?.totalImport
                              ? `${formatToDecimals(tender?.totalImport)} €`
                              : 'N.D.'}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Importo base</Form.Label>
                          <Form.Text as='p'>
                            {tender?.import ? `${formatToDecimals(tender?.import)} €` : 'N.D.'}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: '40px' }}>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Atto &nbsp;</Form.Label>
                          <Form.Text as='p'>{tender?.referenceAct ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Dedicata &nbsp;</Form.Label>
                          <Form.Text as='p'>{selectedDedicated?.[0]?.label ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col className='col-4'>
                        <Form.Group as={Col}>
                          <Form.Label>Oggetto &nbsp;</Form.Label>
                          <Form.Text as='p'>{tender?.subject ?? 'N.D.'}</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                  {sbShow}
                </Tab>

                {/* CIG */}
                <Tab eventKey='cig' title={'CIG'}>
                  <Form id='form-new-tender'>
                      {cigsRows(cigs)}
                  </Form>
                  {sbShow}
                </Tab>

                {/* Controlli */}
                {/* <Tab eventKey='check' title={'Controlli'} className='px-5'>
                  <Form id='form-new-tender'>
                    <Row className='mb-3' style={{ marginTop: '2rem' }}>
                      <Form.Group as={Col} className='pe-2'>
                        <Form.Check
                          inline
                          disabled
                          style={{ marginTop: '0.2rem' }}
                          className='ms-3 summaryCheckBox'
                          type='checkbox'
                          name='dnsh'
                          defaultChecked={tender.dnsh}
                        />
                        <Form.Label className='mb-3'>Rispetto DNSH</Form.Label>
                      </Form.Group>

                      <Form.Group as={Col} className='pe-2'>
                        <Form.Check
                          inline
                          disabled
                          style={{ marginTop: '0.2rem' }}
                          className='ms-3 summaryCheckBox'
                          type='checkbox'
                          name='genderEquality'
                          defaultChecked={tender.genderEquality}
                        />
                        <Form.Label className='mb-3'>
                          Parità di genere e inclusione giovanile
                        </Form.Label>
                      </Form.Group>
                    </Row>

                    <Row className='mb-3' style={{ marginTop: '2rem' }}>
                      <Form.Group as={Col} className='pe-2'>
                        <Form.Check
                          inline
                          disabled
                          style={{ marginTop: '0.2rem' }}
                          className='ms-3 summaryCheckBox'
                          type='checkbox'
                          name='conflictOfInterest'
                          defaultChecked={tender.conflictOfInterest}
                        />
                        <Form.Label className='mb-3'>Conflitto d'interesse</Form.Label>
                      </Form.Group>

                      <Form.Group as={Col} className='pe-2'>
                        <Form.Check
                          inline
                          disabled
                          style={{ marginTop: '0.2rem' }}
                          className='ms-3 summaryCheckBox'
                          type='checkbox'
                          name='other'
                          defaultChecked={tender.other}
                        />
                        <Form.Label className='mb-3'>Valutare altro</Form.Label>
                      </Form.Group>
                    </Row>
                  </Form>
                  {sbShow}
                </Tab> */}

                {/* Documenti */}
                <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                  <UploadDocument entity='tenders' entityId={tender.id} groupedBySection={true} />
                  {sbShow}
                </Tab>

                {/* Contratti aperti */}
                {userRole < 70 && (
                  <Tab eventKey='frameworkContracts' title={'Contratti aperti'}>
                     <MassiveUpload entityId={tender.id} userRole={userRole} isSummary />
                     {/* {sbShow} */}
                  </Tab>
                )}

                {/* Checklist */}
                {userRole < 70 && (
                  <Tab eventKey='checklist' title={'Checklist'}>
                     {activeKey === 'checklist' && (
                     <CheckList entityType='tenders' entityId={id} disabled={tender?.reported} />
                     )}
                  </Tab>
                )}
              </Tabs>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant='secondary' onClick={() => handleCloseModal(setModalIsVisible)}>
              Chiudi
            </Button>
            {activeKey === 'checklist' && (!isSummary || isOe || userRole === 5) ? (
              <Button
                type='submit'
                form='checklist-tenders'
                onClick={() => setIsEdited(true)}
                variant='primary'
                disabled={tender?.reported}>
                <OETrans code='common.edit' fallback={'Modifica'}/>
              </Button>
            ) : null}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
export default TendersSummaryModal;
