import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import { InputNumber } from "primereact";
import moment from "moment";
import StatusBar from "@components/StatusBar";
import { IcoPlus, IcoMinusCircled } from "@icons";
import {
  getCompanies,
  getVatRates,
  getNotices,
  getProjectGroups,
  getUsers,
  getLocations,
  createProject,
  editProject,
  getAllClusters,
  getProjectClusters,
  editProjectCluster,
  createStream,
  editStream,
  deleteStream,
  getAllStreams,
  getDnshCompassClimateMitigations,
  getDnshCompassClimateAdaptations,
  getDnshElements,
} from "@lib/api";
import UploadDocument from "components/form/upload/uploadDocuments";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const initialState = {
  name: "",
  companyId: "",
  manager: "",
  cup: "",
  groupids: [], //non su dbdiagram
  noticeId: "",
  oeManagerId: "",
  durationInMonths: "",
  network: false,
  locationCode: null,
  forecastStartDate: "",
  forecastEndDate: "",
  startDate: "",
  endDate: "",
  description: "",
  dnshClimateMitigation: true,
  dnshClimateAdaptation: true,
  dnshMitigationSector: "",
  dnshMitigationActivity: "",
  dnshMitigationActivityNumber: "",
  dnshAdaptationSector: "",
  dnshAdaptationActivity: "",
  dnshAdaptationActivityNumber: "",
  //dnshElement: '',
  //note: '',
};

function removeWordFromSentence(inputString, wordToRemove) {
  const regex = new RegExp(wordToRemove, "gi");
  return inputString.replace(regex, "");
}

const ProjectModal = ({
  setRefresh,
  showModalProject,
  setShowModalProject,
  project,
  setProjectHeader,
}) => {
  const path = window.location;
  const [thisProject, setThisProject] = useState({});
  const [selectCompany, setSelectCompany] = useState([]);
  const [selectVatRate, setSelectVatRate] = useState([]);
  const [selectNotice, setSelectNotice] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectManager, setSelectManager] = useState([]);
  const [optionsAllLocations, setOptionsAllLocations] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectLocation, setSelectLocation] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [optionsCompanies, setOptionsCompanies] = useState([]);
  const [optionsVatRates, setOptionsVatRates] = useState([]);
  const [optionsGroups, setOptionsGroups] = useState([]);
  const [optionsNotices, setOptionsNotices] = useState([]);
  const [optionsManagers, setOptionsManagers] = useState([]);
  const [optionsRegions, setOptionsRegions] = useState([]);
  const [optionsClusters, setOptionsClusters] = useState([]);
  const [activeKey, setActiveKey] = useState("registry");
  const [streams, setStreams] = useState([]);
  const [originStreams, setOriginStreams] = useState([]);
  const [sbShow, setSbShow] = useState(<></>);
  const [optionsDnshClimateAdaptation, setOptionsDnshClimateAdaptation] =
    useState([]);
  const [optionsDnshClimateMitigation, setOptionsDnshClimateMitigation] =
    useState([]);
  const [selectedDnshClimateAdaptation, setSelectedDnshClimateAdaptation] =
    useState([]);
  const [selectedDnshClimateMitigation, setSelectedDnshClimateMitigation] =
    useState([]);
  const [optionsDnshElements, setOptionsDnshElements] = useState([]);
  const [selectedDnshElement, setSelectedDnshElement] = useState([]);
  const [isPnrr, setIsPnrr] = useState(false);
  const [nameIsInvalid, setNameIsInvalid] = useState(false);
  //console.log('thisProject', thisProject);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setActiveKey("registry");
    setShowModal(false);

    if (path.pathname.includes("projects")) {
      setRefresh(true);
      setThisProject(initialState);
      setSelectCompany([]);
      setSelectVatRate([]);
      setSelectNotice([]);
      setSelectManager([]);
      setSelectLocation([]);
      setClusters([]);
    }
  };

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(
      <StatusBar status={status} message={message} timeout={timeout} />
    );
  };

  useEffect(() => {
    if (showModalProject) {
      let tempProject = {};

      if (project) {
        const { groups, ...restProject } = project;
        restProject.groupids = groups;
        tempProject = restProject;
      } else {
        tempProject = initialState;
      }

      setThisProject(tempProject);

      if (project && project.id) {
        getAllStreams(project.id).then((streams) => {
          setStreams(
            streams.data.map((stream) => ({
              id: stream.id,
              name: stream.name,
              projectId: stream.projectId,
              streamId: stream.streamId,
            }))
          );
          setOriginStreams(
            streams.data.map((stream) => ({
              id: stream.id,
              name: stream.name,
              projectId: stream.projectId,
              streamId: stream.streamId,
            }))
          );
        });
      }

      getCompanies().then((companies) => {
        if (companies.success === true) {
          setOptionsCompanies(
            companies.data.map((company) => ({
              value: company.id,
              label: company.name,
            }))
          );
        }
      });

      getVatRates().then((vatRates) => {
        if (vatRates.success === true) {
          setOptionsVatRates(
            vatRates.data.map((vatRate) => ({
              value: vatRate.code,
              label: vatRate.name,
            }))
          );
        }
      });

      getNotices().then((notices) => {
        if (notices.success === true) {
          setOptionsNotices(
            notices.data.map((notice, i) => ({
              value: notice.id,
              label: notice.call,
              fund: notice.fund.code,
              // fund: i === 0 ? 'rrr' : notice.fund.code,
            }))
          );
        }
      });

      getUsers().then((users) => {
        if (users.success === true) {
          setOptionsManagers(
            users.data.map((user) => ({
              value: user.id,
              label: user.firstName + " " + user.lastName,
            }))
          );
        }
      });

      getLocations().then((locations) => {
        if (locations.success === true) {
          const tempLocations = [...locations.data];

          setOptionsAllLocations(
            tempLocations
              .sort((a, b) => a.nomeComune.localeCompare(b.nomeComune))
              .map((location) => ({
                value: location.codComuneNumerico,
                label: location.nomeComune,
                codRegione: location.codRegione,
              }))
          );

          const seen = new Set();

          const onlyRegions = tempLocations.filter((location) => {
            const duplicate = seen.has(location.codRegione);
            seen.add(location.codRegione);
            return !duplicate;
          });

          setOptionsRegions(
            onlyRegions
              .sort((a, b) => a.nomeRegione.localeCompare(b.nomeRegione))
              .map((regions) => ({
                value: regions.codRegione,
                label: regions.nomeRegione,
              }))
          );
        }
      });

      getDnshCompassClimateAdaptations().then((dnshClimateAdaptations) => {
        if (dnshClimateAdaptations.success === true) {
          setOptionsDnshClimateAdaptation(
            dnshClimateAdaptations.data.map((dnshClimateAdaptation) => ({
              label: `${dnshClimateAdaptation.sector} - ${dnshClimateAdaptation.activity}`,
              value: dnshClimateAdaptation.activityNumber,
              sector: dnshClimateAdaptation.sector,
              activity: dnshClimateAdaptation.activity,
            }))
          );
        }
      });

      getDnshCompassClimateMitigations().then((dnshClimateMitigations) => {
        if (dnshClimateMitigations.success === true) {
          setOptionsDnshClimateMitigation(
            dnshClimateMitigations.data.map((dnshClimateMitigation) => ({
              label: `${dnshClimateMitigation.sector} - ${dnshClimateMitigation.activity}`,
              value: dnshClimateMitigation.activityNumber,
              sector: dnshClimateMitigation.sector,
              activity: dnshClimateMitigation.activity,
            }))
          );
        }
      });

      getDnshElements().then((dnshElements) => {
        if (dnshElements.success === true) {
          setOptionsDnshElements(
            dnshElements.data.map((dnshElement) => ({
              label: `${dnshElement.name}`,
              value: dnshElement.code,
            }))
          );
        }
      });
    }
  }, [showModalProject, project]);

  useEffect(() => {
    if (thisProject.companyId) {
      //solo gruppi disponibili in base alla società scelta
      getProjectGroups(thisProject.companyId).then((groups) => {
        if (groups.success === true) {
          let tempOptionsGroups = groups.data.map((group) => ({
            value: group.id,
            label: group.name,
          }));

          setOptionsGroups(tempOptionsGroups);

          if (thisProject.id) {
            //gruppi associati
            setSelectedGroups(
              tempOptionsGroups.filter((option) => {
                return thisProject.groupids.some((group) => {
                  return (group.id || group.value) === option.value;
                });
              })
            );
          }
        }
      });

      getAllClusters(thisProject.companyId).then((clusters) => {
        let tempOptionsClusters = clusters.data.map((cluster) => ({
          value: cluster.id,
          label: cluster.name,
        }));

        setOptionsClusters(tempOptionsClusters);

        if (thisProject.id) {
          //cluster associati
          getProjectClusters(thisProject.id).then((assClusters) => {
            setClusters(
              tempOptionsClusters.filter((option) => {
                return assClusters.data.some((assCluster) => {
                  return assCluster.id === option.value;
                });
              })
            );
          });
        }
      });
    }
  }, [thisProject.companyId, thisProject.groupids, thisProject.id]);

  useEffect(() => {
    if (thisProject.id) {
      setSelectCompany(
        optionsCompanies.filter(
          (option) => option.value === thisProject.companyId
        )
      );
      setSelectVatRate(
        optionsVatRates.filter(
          (option) => option.value === thisProject.vatRate
        )
      );

      setSelectManager(
        optionsManagers.filter(
          (option) => option.value === thisProject.oeManagerId
        )
      );
      setSelectNotice(
        ...optionsNotices.filter(
          (option) => option.value === thisProject.noticeId
        )
      );

      setSelectedDnshClimateAdaptation(
        optionsDnshClimateAdaptation.filter(
          (option) => option.value === thisProject.dnshAdaptationActivityNumber
        )
      );
      setSelectedDnshClimateMitigation(
        optionsDnshClimateAdaptation.filter(
          (option) => option.value === thisProject.dnshMitigationActivityNumber
        )
      );
      setSelectedDnshElement(
        optionsDnshElements.filter(
          (option) => option.value === thisProject.dnshElement
        )
      );
    }
  }, [
    optionsCompanies,
    optionsManagers,
    optionsNotices,
    optionsVatRates,
    thisProject,
    optionsDnshClimateAdaptation,
    optionsDnshClimateMitigation,
    optionsDnshElements,
  ]);

  useEffect(() => {
    if (thisProject.id) {
      setSelectLocation(
        optionsAllLocations.filter(
          (opt) => opt.value === thisProject.locationCode
        )
      );
    }
  }, [optionsAllLocations, thisProject]);

  useEffect(() => {
    if (project && project.id && project.location) {
      setSelectedRegion(
        optionsRegions.filter(
          (option) => option.value === project.location.codRegione
        )
      );
    }
  }, [optionsRegions, project]);

  useEffect(() => {
    if (selectNotice) {
      setIsPnrr(selectNotice?.fund === "PNRR");
    }
  }, [selectNotice]);

  const onChange = (e, index, fieldName, setSelect, list) => {
    //console.log('e:', e);

    let tempNewProject = { ...thisProject };
    let value;

    switch (fieldName) {
      case "name":
      case "description":
      case "note":
      case "manager":
      case "cup":
      case "localProjectCode":
      case "projectCodeStamp":
      case "applicant":
      case "applicantEmail":
      case "networkNote":
        value = e.target.value;
        tempNewProject[fieldName] = value;
        break;
      case "forecastStartDate":
      case "forecastEndDate":
      case "startDate":
      case "endDate":
        value = e.target.value === "" ? null : e.target.value;
        tempNewProject[fieldName] = value;
        break;
      case "network":
        value = e.target.checked;
        tempNewProject[fieldName] = value;

        if (!value) { 
         tempNewProject.networkNote = '';
        } else {
         tempNewProject.locationCode = '';
         setSelectLocation([]);
         setSelectedRegion([]);
        }
        break;
      case "dnshClimateAdaptation":
      case "dnshClimateMitigation":
        value = e.target.checked;
        tempNewProject[fieldName] = value;
        if (!value) {
          const correctObjProperty = removeWordFromSentence(
            fieldName,
            "Climate"
          );
          tempNewProject[`${correctObjProperty}ActivityNumber`] = "";
          tempNewProject[`${correctObjProperty}Sector`] = "";
          tempNewProject[`${correctObjProperty}Activity`] = "";
          setSelect([]);
        }
        break;
      case "Adaptation":
      case "Mitigation":
        tempNewProject[`dnsh${fieldName}ActivityNumber`] = e ? e.value : "";
        tempNewProject[`dnsh${fieldName}Sector`] = e ? e.sector : "";
        tempNewProject[`dnsh${fieldName}Activity`] = e ? e.activity : "";

        setSelect(e ?? []);
        break;
      case "durationInMonths":
        value = Number(e.target.value);

        tempNewProject[fieldName] = value;
        break;
      case "companyId":
      case "vatRate":
      case "oeManagerId":
      case "dnshElement":
        value = e ? e.value : null;
        tempNewProject[fieldName] = value;

        setSelect(e ?? []);

        if (fieldName === "companyId") {
          tempNewProject.groupids = [];
          setSelectedGroups([]);
        }

        break;
      case "locationCode":
      case "regione":
        setSelect(e ? [e] : []);

        if (fieldName === "regione") {
          tempNewProject.locationCode = "";
          setSelectLocation([]);
        } else if (fieldName === "locationCode") {
          value = e ? e.value : "";
          tempNewProject[fieldName] = value;
        }

        break;
      case "groupids":
        value = e ?? "";
        tempNewProject[fieldName] = value;

        setSelect(e ?? []);
        break;
      case "noticeId":
        value = e ? e.value : "";
        tempNewProject[fieldName] = value;

        if (e.fund !== "PNRR") {
          setSelectedDnshElement([]);
          tempNewProject["dnshElement"] = "";
        }

        setSelect(e ?? []);
        break;
      case "streams":
      case "businessAreaIds":
        let tempList = [...list];

        if (fieldName === "streams") {
          tempList[index] = {
            ...tempList[index],
            [e.originalEvent ? e.originalEvent.target.name : e.target.name]:
              e.originalEvent ? Number(e.value) : e.target.value,
          };
        } else if (fieldName === "businessAreaIds") {
          tempList = e ?? [];
        }

        setSelect(tempList); //as setList
        break;
      default:
        break;
    }

    setThisProject(tempNewProject);
  };

  const onBlur = (value, setState) => {
    if (value && value.includes("/")) {
      setState(true);
    } else {
      setState(false);
    }
  };

  const handleSaveProject = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    if (
      !thisProject.name ||
      !thisProject.companyId ||
      !thisProject.noticeId ||
      thisProject.groupids.length === 0 ||
      !thisProject.cup ||
      /* !thisProject.durationInMonths || */
      !thisProject.forecastEndDate ||
      !thisProject.forecastStartDate ||
      (!thisProject.network && !thisProject.locationCode)
    ) {
      handleStatusBar("error", translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
      return;
    }

    if (thisProject.name && thisProject.name.includes("/")) {
      handleStatusBar(
        "error",
        'Carattere vietato: il nome progetto non può contenere il simbolo "/"',
        false
      );
      return;
    }

    if (
      thisProject.forecastStartDate &&
      thisProject.forecastEndDate &&
      new Date(thisProject.forecastStartDate).getTime() >
        new Date(thisProject.forecastEndDate).getTime()
    ) {
      handleStatusBar(
        "error",
        "L'avvio previsto deve avere una data antecedente alla fine prevista",
        false
      );
      return;
    }
    if (
      thisProject.endDate &&
      new Date(thisProject.startDate).getTime() >
        new Date(thisProject.endDate).getTime()
    ) {
      handleStatusBar(
        "error",
        "L'avvio effettivo deve avere una data antecedente alla fine effettiva",
        false
      );
      return;
    }

    if (
      (thisProject.dnshClimateMitigation === true &&
        !thisProject.dnshMitigationActivityNumber) ||
      (thisProject.dnshClimateAdaptation === true &&
        !thisProject.dnshAdaptationActivityNumber)
    ) {
      handleStatusBar(
        "error",
        "Selezionare un'attività nel campo Dnsh Adaptation e/o Dnsh Mitigation oppure disabilitare le opzioni",
        false
      );
      return;
    }

    const { createdAt, createdBy, updatedAt, updatedBy, ...restProject } =
      thisProject;

    restProject.groupids = restProject.groupids.map(
      (group) => group.value || group.id
    );

    if (project && thisProject.dnshElement === project.dnshElement) {
      delete restProject.dnshElement;
    }

    if (thisProject.network && !thisProject.locationCode) {
      delete restProject.locationCode;
    }

    if (thisProject.id) {
      /* if (!thisProject.startDate) {
               delete restProject.startDate;
            }
    
            if (!thisProject.endDate) {
               delete restProject.endDate;
            } */

      if (streams.length > 0) {
        const set = new Set();
        const isNotNameStreamsUnique = streams.some(
          (object) => set.size === (set.add(object.streamId), set.size)
        );

        if (isNotNameStreamsUnique) {
          handleStatusBar(
            "error",
            "Gli ID stream non possono essere uguali",
            false
          );
          return;
        }
      }

      const editResult = await editProject(restProject.id, restProject);

      if (editResult.success) {
        const { groups, ...restResult } = editResult.data;
        const newResult = { groupids: groups, ...restResult };

        setThisProject(newResult);

        if (setProjectHeader) setProjectHeader(editResult.data);
        setNameIsInvalid(false);

        const dataClusters = {
          businessAreaIds: clusters.map(
            (cluster) => cluster.value || cluster.id
          ),
        };
        const clustersResult = await editProjectCluster(
          editResult.data.id,
          dataClusters
        );
        //console.log('clustersResult', clustersResult);

        if (setRefresh) setRefresh(true);

        let resultOfActions = [true];
        let resultOfMessages = [];

        let [streamResults] = await Promise.all([
          handleItemList(
            "streams",
            originStreams,
            streams,
            deleteStream,
            editStream,
            createStream,
            thisProject.id
          ),
        ]);
        //console.log('streamResults', streamResults);

        const resultOfAll = resultOfActions.concat(
          resultOfActions,
          clustersResult.success,
          streamResults.resultOfActions
        );
        const resultOfAllMessages = resultOfMessages.concat(
          streamResults ? streamResults.messageResult : [],
          !clustersResult ? clustersResult.message : []
        );
        //console.log('resultOfAll', resultOfAll);

        getAllStreams(editResult.data.id).then((retrievedStreams) => {
          //console.log('retrievedStreams', retrievedStreams);
          setStreams(retrievedStreams.data);
          setOriginStreams(retrievedStreams.data);
        });

        if (resultOfAll.length > 0 && resultOfAll.every((el) => el === true)) {
          handleStatusBar("success", translate('common.message.edited', 'Elemento modificato con successo'), true);
        } else {
          /* handleStatusBar(
                  'error',
                  streamResults.messageResult?.success === false
                     ? Object.values(streamResults.messageResult.data).join()
                     : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
                  false
               ); */
          handleStatusBar(
            "error",
            resultOfAllMessages.length > 0
              ? Object.values(resultOfAllMessages[0].data).join()
              : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
          );
        }
      } else {
        handleStatusBar(
          "error",
          editResult.success === false
            ? Object.values(editResult.data).join()
            : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
          false
        );
      }
    } else {
      const result = await createProject(restProject);

      if (result.success) {
        const { groups, ...restResult } = result.data;
        const newResult = { groupids: groups, ...restResult };

        setThisProject(newResult);

        if (setProjectHeader) setProjectHeader(result.data);
        setNameIsInvalid(false);

        const dataClusters = {
          businessAreaIds: clusters.map(
            (cluster) => cluster.value || cluster.id
          ),
        };
        const clustersResult = await editProjectCluster(
          result.data.id,
          dataClusters
        );
        //console.log('clustersResult', clustersResult);

        if (clustersResult.success) {
          handleStatusBar("success", "Progetto creato con successo", true);
          setActiveKey("stream");

          setTimeout(() => {
            getProjectClusters(result.data.id).then((associatedClusters) => {
              setClusters(
                optionsClusters.filter((option) => {
                  return associatedClusters.data.some((assCluster) => {
                    return assCluster.id === option.value;
                  });
                })
              );
            });
          }, 2000);
        } else {
          handleStatusBar(
            "error",
            result.success === false
              ? Object.values(result.data).join()
              : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
          );
        }
      } else {
        handleStatusBar(
          "error",
          result.success === false
            ? Object.values(result.data).join()
            : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
          false
        );
      }
    }
  };

  const handleItemList = async (
    entityType,
    originItemList,
    itemList,
    deleteAPI,
    editAPI,
    createAPI,
    entityId
  ) => {
    let resultOfActions = [];

    const oldItemsDeleted = originItemList.filter((object1) => {
      return !itemList.some((object2) => {
        return object1.id === object2.id;
      });
    });

    if (oldItemsDeleted.length > 0) {
      oldItemsDeleted.forEach(async (item) => {
        const deleteResult = await deleteAPI(entityId, item.id);
        resultOfActions.push(deleteResult.success);
      });
    }

    let validationFields = [];

    switch (entityType) {
      case "streams":
        validationFields = ["streamId", "name"];
        break;
      default:
        validationFields = [];
    }

    let result;
    let messageResult;

    await Promise.all(
      itemList.map(async (item) => {
        const resultOfEvery = validationFields.every((field) => item[field]);

        if (resultOfEvery) {
          if (item.id) {
            const { createdAt, createdBy, updatedAt, updatedBy, ...restItem } =
              item;
            result = await editAPI(entityId, restItem);
            //console.log('resultEdit:', result);
            if (result.success === false) messageResult = result;
            resultOfActions.push(result.success);
          } else {
            item.projectId = entityId;
            result = await createAPI(entityId, item);
            //console.log('resultNew', result);
            if (result.success === false) messageResult = result;
            resultOfActions.push(result.success);
          }
        } else {
          messageResult = {
            success: false,
            data: { validation: ["Stream non salvato. Riempi tutti i campi"] },
          };
          resultOfActions.push(false);
          return;
        }
      })
    );

    //console.log('messageResult:', messageResult);
    return { resultOfActions, messageResult };
  };

  const addExpenseItem = (typeArray, setTypeArray) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.push({});

    setTypeArray(tempTypeArray);
  };

  const removeExpenseItem = (typeArray, setTypeArray, index) => {
    //delStream(typeArray[index])

    let tempTypeArray = [...typeArray];

    tempTypeArray.splice(index, 1);

    setTypeArray(tempTypeArray);
  };

  const streamRows = (items, setItems) => {
    let rows = [];

    if (items) {
      if (items.length === 0) {
        items = [{}];
      }

      let lastIndex = items.length - 1;

      rows = items.map((el, i) => {
        const isThisRowFilled = el.streamId && el.name ? true : false;

        return (
          <Row key={i} style={{ marginBottom: "40px" }}>
            <Form.Group as={Col} className="col-2">
              {i === 0 && (
                <Form.Label>
                  ID Stream<span className="required-field">*</span>
                </Form.Label>
              )}
              <InputNumber
                name="streamId"
                value={el.streamId}
                onChange={(e) => onChange(e, i, "streams", setStreams, streams)}
                min={0}
                minFractionDigits={0}
                maxFractionDigits={2}
                mode="decimal"
                useGrouping={false}
                disabled={
                  project?.editables ? !project?.editables?.company : false
                }
              />
            </Form.Group>
            <Form.Group as={Col} className="col-5">
              {i === 0 && (
                <Form.Label>
                  Denominazione<span className="required-field">*</span>
                </Form.Label>
              )}
              <Form.Control
                type="text"
                name="name"
                value={el.name ?? ""}
                onChange={(e) => onChange(e, i, "streams", setStreams, streams)}
                disabled={
                  project?.editables ? !project?.editables?.company : false
                }
              />
            </Form.Group>
            {((project?.editables && project?.editables?.company) ||
              !project?.editables) && (
              <Form.Group as={Col} className="col-2">
                {i === 0 && <Form.Label className="w-100">&nbsp;</Form.Label>}
                {i === lastIndex ? (
                  <Button
                    variant="link"
                    className="me-2"
                    onClick={() => addExpenseItem(items, setItems)}
                    disabled={!isThisRowFilled}
                  >
                    <IcoPlus className="second-color ico-small indicators" />
                  </Button>
                ) : null}
                {items.length !== 1 ? (
                  <Button
                    variant="link"
                    onClick={() => removeExpenseItem(items, setItems, i)}
                  >
                    <IcoMinusCircled className="red-color ico-small indicators" />
                  </Button>
                ) : null}
              </Form.Group>
            )}
          </Row>
        );
      });
    }

    return rows;
  };

  return (
    <>
      <Modal
        show={showModalProject}
        size="xl"
        className="modal-with-nav"
        dialogClassName="modal-75w"
        onHide={() => handleCloseModal(setShowModalProject)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {thisProject.id ? "Modifica Progetto" : "Nuovo Progetto"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            className="mb-4"
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
            fill
            variant="pills"
          >
            {/* Anagrafica */}
            <Tab eventKey="registry" title={translate('common.registry', 'Anagrafica')}>
              {activeKey === "registry" && (
                <>
                  <Form onSubmit={handleSaveProject} id="form-new-project">
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          Nome<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={thisProject.name ?? ""}
                          onChange={(e) => onChange(e, 0, "name")}
                          onBlur={(e) =>
                            onBlur(e.target.value, setNameIsInvalid)
                          }
                          isInvalid={nameIsInvalid}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="ps-1 position-absolute"
                        >
                          Carattere vietato: il nome non può contenere il
                          simbolo "/"
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Società<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="companyId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectCompany}
                          options={optionsCompanies}
                          onChange={(e) =>
                            onChange(e, 0, "companyId", setSelectCompany)
                          }
                          isDisabled={
                            project?.editables
                              ? !project?.editables?.company
                              : false
                          }
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          CUP<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="cup"
                          value={thisProject.cup ?? ""}
                          onChange={(e) => onChange(e, 0, "cup")}
                          disabled={
                            project?.editables
                              ? !project?.editables?.cup
                              : false
                          }
                        />
                      </Form.Group>
                    </Row>

                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          Gruppi<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          closeMenuOnSelect={false}
                          name="groupids"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedGroups}
                          options={optionsGroups}
                          onChange={(e) =>
                            onChange(e, 0, "groupids", setSelectedGroups)
                          }
                          isDisabled={thisProject?.companyId ? false : true}
                          isMulti
                          isClearable
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Bando<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="noticeId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectNotice}
                          options={optionsNotices}
                          onChange={(e) =>
                            onChange(e, 0, "noticeId", setSelectNotice)
                          }
                          isDisabled={
                            project?.editables
                              ? !project?.editables?.notice
                              : false
                          }
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Responsabile</Form.Label>
                        <Form.Control
                          type="text"
                          name="manager"
                          value={thisProject.manager ?? ""}
                          onChange={(e) => onChange(e, 0, "manager")}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>PM OE</Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="oeManagerId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectManager}
                          options={optionsManagers}
                          onChange={(e) =>
                            onChange(e, 0, "oeManagerId", setSelectManager)
                          }
                          isClearable
                        />
                      </Form.Group>
                    </Row>

                    <Row style={{ marginBottom: "40px" }}>
                      {/* <Form.Group as={Col} className='px-4 col-3'>
                                 <Form.Label className='mb-3'>
                                    Durata <span className='text-muted'>(in mesi)</span>
                                    <span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='number'
                                    name='durationInMonths'
                                    value={thisProject.durationInMonths ?? ''}
                                    onChange={(e) => onChange(e, 0, 'durationInMonths')}
                                 />
                                 </Form.Group> */}

                      <Form.Group as={Col}>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="network"
                          defaultChecked={thisProject.network}
                          onClick={(e) => onChange(e, 0, "network")}
                        />
                        <Form.Label>Progetto a rete</Form.Label>
                        <Form.Control
                           type="text"
                           name="networkNote"
                           placeholder={thisProject.network ? 'Localizzazione*' : ''}
                           value={thisProject.networkNote ?? ''}
                           onChange={(e) => onChange(e, 0, "networkNote")}
                           disabled={!thisProject.network}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Localizzazione (Regione)
                          {!thisProject.network ? (
                            <span className="required-field">&nbsp;*</span>
                          ) : null}
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Seleziona Regione..."
                          value={selectedRegion}
                          options={optionsRegions}
                          onChange={(e) =>
                            onChange(e, 0, "regione", setSelectedRegion)
                          }
                          isClearable
                          isDisabled={thisProject.network}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Localizzazione (Comune)
                          {!thisProject.network ? (
                            <span className="required-field">&nbsp;*</span>
                          ) : null}
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="locationCode"
                          placeholder="Seleziona Comune..."
                          value={selectLocation}
                          options={
                            selectedRegion.length > 0
                              ? optionsAllLocations.filter((allLoc) => {
                                  return selectedRegion.some((selectReg) => {
                                    return (
                                      selectReg.value === allLoc.codRegione
                                    );
                                  });
                                })
                              : []
                          }
                          onChange={(e) =>
                            onChange(e, 0, "locationCode", setSelectLocation)
                          }
                          isClearable
                          isDisabled={selectedRegion.length === 0 || thisProject.network}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Cluster geografici</Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="businessAreaIds"
                          placeholder="Seleziona Cluster..."
                          value={clusters}
                          options={optionsClusters}
                          onChange={(e) =>
                            onChange(
                              e,
                              0,
                              "businessAreaIds",
                              setClusters,
                              clusters
                            )
                          }
                          isMulti
                          isClearable
                        />
                      </Form.Group>
                    </Row>

                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          Avvio previsto
                          <span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="forecastStartDate"
                          //value={new Date(thisProject.forecastStartDate).toLocaleDateString('en-CA')}
                          value={
                            thisProject.forecastStartDate
                              ? moment(thisProject.forecastStartDate).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          onChange={(e) => onChange(e, 0, "forecastStartDate")}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Fine prevista<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="forecastEndDate"
                          //value={new Date(thisProject.forecastEndDate).toLocaleDateString('en-CA')}
                          value={
                            thisProject.forecastEndDate
                              ? moment(thisProject.forecastEndDate).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          onChange={(e) => onChange(e, 0, "forecastEndDate")}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Avvio effettivo</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          //value={new Date(thisProject.startDate).toLocaleDateString('en-CA')}
                          value={
                            thisProject.startDate
                              ? moment(thisProject.startDate).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          onChange={(e) => onChange(e, 0, "startDate")}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Fine effettiva</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          //value={new Date(thisProject.endDate).toLocaleDateString('en-CA')}
                          value={
                            thisProject.endDate
                              ? moment(thisProject.endDate).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) => onChange(e, 0, "endDate")}
                        />
                      </Form.Group>
                    </Row>

                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="dnshClimateMitigationCheck"
                          defaultChecked={thisProject.dnshClimateMitigation}
                          onClick={(e) =>
                            onChange(
                              e,
                              0,
                              "dnshClimateMitigation",
                              setSelectedDnshClimateMitigation
                            )
                          }
                        />
                        <Form.Label>
                          Dnsh Mitigation
                          {thisProject.dnshClimateMitigation && (
                            <>
                              <span className="required-field">*</span>
                            </>
                          )}
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isDisabled={!thisProject.dnshClimateMitigation}
                          name="Mitigation"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedDnshClimateMitigation}
                          options={optionsDnshClimateMitigation}
                          onChange={(e) =>
                            onChange(
                              e,
                              0,
                              "Mitigation",
                              setSelectedDnshClimateMitigation
                            )
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="dnshClimateAdaptationCheck"
                          defaultChecked={thisProject.dnshClimateAdaptation}
                          onClick={(e) =>
                            onChange(
                              e,
                              0,
                              "dnshClimateAdaptation",
                              setSelectedDnshClimateAdaptation
                            )
                          }
                        />
                        <Form.Label>
                          Dnsh Adaptation
                          {thisProject.dnshClimateAdaptation && (
                            <>
                              <span className="required-field">*</span>
                            </>
                          )}
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isDisabled={!thisProject.dnshClimateAdaptation}
                          name="Adaptation"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedDnshClimateAdaptation}
                          options={optionsDnshClimateAdaptation}
                          onChange={(e) =>
                            onChange(
                              e,
                              0,
                              "Adaptation",
                              setSelectedDnshClimateAdaptation
                            )
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        {/* <Form.Check
                      inline
                      style={{ marginTop: '0.2rem' }}
                      className='me-3'
                      type='checkbox'
                      name='dnshClimateMitigationCheck'
                      defaultChecked={thisProject.dnshClimateMitigation}
                      onClick={(e) =>
                        onChange(e, 0, 'dnshClimateMitigation', setSelectedDnshClimateMitigation)
                      }
                    /> */}
                        <Form.Label>Dnsh Element</Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isDisabled={
                            !isPnrr ||
                            (project?.editables && !project?.editables?.dnsh)
                          }
                          name="Element"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedDnshElement}
                          options={optionsDnshElements}
                          onChange={(e) =>
                            onChange(
                              e,
                              0,
                              "dnshElement",
                              setSelectedDnshElement
                            )
                          }
                          isClearable
                        />
                      </Form.Group>
                    </Row>

                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>Descrizione</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="description"
                          value={thisProject.description ?? ""}
                          onChange={(e) => onChange(e, 0, "description")}
                        />
                      </Form.Group>
                    </Row>

                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label className="mb-3">Note</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="note"
                          value={thisProject.note ?? ""}
                          onChange={(e) => onChange(e, 0, "note")}
                        />
                      </Form.Group>
                    </Row>
                  </Form>

                  {sbShow}
                </>
              )}
            </Tab>
            <Tab
              eventKey="otherinfo"
              title={"Altre informazioni"}
              //disabled={!thisProject.id}
            >
              {activeKey === "otherinfo" && (
                <>
                  <Form onSubmit={handleSaveProject} id="form-new-project">
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          Agevolabilità IVA
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="vatRate"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectVatRate ?? ""}
                          options={optionsVatRates}
                          onChange={(e) =>
                            onChange(e, 0, "vatRate", setSelectVatRate)
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>
                          CLP
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="localProjectCode"
                          value={thisProject.localProjectCode ?? ""}
                          onChange={(e) => onChange(e, 0, "localProjectCode")}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Codice Progetto (Timbratura)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="projectCodeStamp"
                          value={thisProject.projectCodeStamp ?? ""}
                          onChange={(e) => onChange(e, 0, "projectCodeStamp")}
                        />
                      </Form.Group>
                    </Row>
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          Richiedente (Timbratura)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="applicant"
                          value={thisProject.applicant ?? ""}
                          onChange={(e) => onChange(e, 0, "applicant")}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>
                          E-mail Richiedente (Timbratura)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="applicantEmail"
                          value={thisProject.applicantEmail ?? ""}
                          onChange={(e) => onChange(e, 0, "applicantEmail")}
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                  {sbShow}
                </>
              )}
            </Tab>

            {/* Setup stream */}
            <Tab
              eventKey="stream"
              title={"Setup stream"}
              disabled={!thisProject.id}
            >
              {activeKey === "stream" && (
                <>
                  <Form onSubmit={handleSaveProject} id="form-new-project">
                    {streamRows(streams, setStreams, "capex")}
                  </Form>
                  {sbShow}
                </>
              )}
            </Tab>

            {/* Documenti */}
            <Tab eventKey="docs" title={<OETrans code='common.documents' fallback={'Documenti'} />} disabled={!thisProject.id}>
              {activeKey === "docs" && (
                <>
                  <UploadDocument
                    entity="projects"
                    entityId={thisProject.id}
                    filterBySection={["project"]}
                  />
                  {sbShow}
                </>
              )}
            </Tab>
          </Tabs>

          {(activeKey !== "docs" && activeKey !== "otherinfo") && (
            <Row>
              <Col className="col-12">
                <span className="label">
                  <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                </span>
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalProject)}
          >
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button variant="primary" onClick={(e) => handleSaveProject(e)}>
            {thisProject.id ? <OETrans code='common.edit' fallback={'Modifica'}/> : <OETrans code='common.create' fallback={'Crea'}/>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ProjectModal;
